import React from 'react';
import classes from './PlannerFooter.module.css';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const PlannerFooter = () => {
  return (
    <Box className={classes.container}>
      <Grid container className={`${classes.mainContent}`}>
        <Grid item xs={5}>
          <Box className={classes.content}>
            <Typography variant='s16_w700'>
              Copyright @2023. All rights reserved.
            </Typography>
            <Typography variant='body1'>
              Food Fe is more than just a food-tech platform; It’s a mission to
              make a positive impact on society and our environment. We aren’t
              merely running a business; we’re stirring up a revolution, one
              meal at a time.
            </Typography>
            <Box className={classes.iconContainer}>
              {/* <TwitterIcon style={{ color: '#F5825E' }} /> */}
              <Box
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <FacebookIcon style={{ color: '#F5825E' }} />
              </Box>
              <Box
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <InstagramIcon
                  style={{
                    color: '#F5825E',
                  }}
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/foodfeapp?utm_source=qr&igsh=cGc2cWp1N3BlZDJj'
                    )
                  }
                />
              </Box>
              <Box
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <LinkedInIcon
                  style={{ color: '#F5825E' }}
                  onClick={() =>
                    window.open('https://www.linkedin.com/company/foodfe/')
                  }
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          {/* <Box className={classes.content}>
            <Typography variant='s16_w600_orange'>How to use this ?</Typography>
            <Typography variant='s16_w600_orange'>
              Helpful information
            </Typography>
            <Box className={classes.boxInfo}>
              <Typography variant='s16_w600_orange' component='span'>
                Facing problems using this app?
              </Typography>
              <Button variant='outlined'>Raise a ticket</Button>
            </Box>
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlannerFooter;
