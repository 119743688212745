import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  mealList: [],
  filteredList: [],
  allergiesList: [],
  filteredAllergiesList: [],
  showOnly: [],
  searchValue: '',
};

const mealSlice = createSlice({
  name: 'meal',
  initialState,
  reducers: {
    addAllMeals: (state, action) => {
      state.mealList = action.payload;
      state.filteredList = action.payload;
    },
    searchMealList: (state, action) => {
      const { searchName, language } = action.payload;
      state.searchValue = searchName;

      if (searchName === '') {
        state.filteredList = [...state.mealList];
        return;
      }
      console.log(`Searching ${searchName}`);

      state.filteredList = filterMeals(state, language);
    },
    addAllAllergies: (state, action) => {
      state.allergiesList = action.payload;
      state.filteredAllergiesList = action.payload;
    },
    searchAllergiesList: (state, action) => {
      const { searchName, language } = action.payload;
      if (searchName === '') {
        state.filteredAllergiesList = [...state.allergiesList];
        return;
      }

      const filteredList = state.allergiesList?.filter((allergy) => {
        return language === 'English'
          ? allergy.nameEn?.toLowerCase().includes(searchName?.toLowerCase())
          : allergy.nameNo?.toLowerCase().includes(searchName?.toLowerCase());
      });

      console.log('filter', searchName);
      console.dir(filteredList);

      state.filteredAllergiesList = filteredList;
    },
    updateShowOnlyFilter: (state, action) => {
      const { filterType, language } = action.payload;
      console.log('updateShowOnlyFilter', filterType, language);
      if (state.showOnly.includes(filterType)) {
        state.showOnly = state.showOnly.filter((item) => item !== filterType);
      } else {
        state.showOnly = [...state.showOnly, filterType];
      }
      console.log('updateShowOnlyFilter', 'after push');
      console.dir(state.showOnly);
      state.filteredList = filterMeals(state, language);
    },
    reset: () => {
      return initialState;
    }
  },
});

const filterMeals = (state, language = 'English') => {
  return state.mealList?.filter((meal) => {
    return (
      (state.showOnly.includes('Halal') ? meal.isHalal : true) &&
      (state.showOnly.includes('Vegetarian') ? meal.isVeg : true) &&
      (state.searchValue.length > 0
        ? language === 'English'
          ? meal.details[0].name
              ?.toLowerCase()
              .includes(state.searchValue?.toLowerCase())
          : meal.details[1].name
              ?.toLowerCase()
              .includes(state.searchValue?.toLowerCase())
        : true)
    );
  });
};

export const MealAction = mealSlice.actions;
export default mealSlice;
