import { v4 as uuidv4 } from 'uuid';

export const headers =
  process.env.NODE_ENV === 'production'
    ? {
        TargetCanteenId: '9af33add-22a4-4169-9390-6d9eeeca5e94',
        TargetOrganizationId: '75bf45e1-eeb8-42ba-aec4-a54324229bf1',
        TargetUserId: '2d75b605-7d19-482b-a340-807506ef6e57',
        'X-TraceId': uuidv4(),
      }
    : {
        TargetCanteenId: 'bf996482-df66-4d22-b9e6-c48c48310b30',
        TargetOrganizationId: '16f5a982-7d5c-4ddd-ba96-9f929af35be1',
        TargetUserId: '7abadfb3-2737-4c48-a101-4c02094ab9f6',
        'X-TraceId': uuidv4(),
      };
