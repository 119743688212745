import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from '../../../../utils/queryKeys';
import { deleteSchedule } from '../ScheduleApi';
import { useSelector } from 'react-redux';

const DeleteIconActive =
  process.env.PUBLIC_URL + '/assets/menu_planner/delete_Icon_red.svg';
const DeleteIconDisable =
  process.env.PUBLIC_URL + '/assets/menu_planner/delete_Icon_grey.svg';

const CreateScheduleTable = ({ scheduleData }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  let toastId;

  const { date } = useSelector((state) => state.scheduleMeal);
  const [isPastDate, setPastDate] = useState(false);
  console.log('isPastDate', isPastDate);

  useEffect(() => {
    (function () {
      setPastDate(new Date(Date.parse(date)) < new Date());
    })();
  }, [date]);

  const handleErr = async (err) => {
    console.log('handleErr');
    console.dir(err);
    toast.dismiss(toastId);
    try {
      toast.error(err.response.data.message);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const handleSuccessResponse = async (data) => {
    toast.dismiss(toastId);
    toast.success('Scheduled meal deleted successfully');
    queryClient.invalidateQueries({ queryKey: [queryKeys.getAllScheduleData] });
  };

  const handleDeleteClick = (schedule) => {
    console.log(schedule);
    toastId = toast.loading('Processing...');
    deleteScheduleItem({
      data: {
        date: schedule.date,
        scheduleType: schedule.scheduleType,
        mealPlanId: schedule.mealPlan.id,
        mealId: schedule.externalId,
      },
    });
    // navigate()
  };

  const { mutate: deleteScheduleItem } = useMutation(
    ({ data }) => deleteSchedule(data),
    {
      onSuccess: handleSuccessResponse,
      onError: handleErr,
    }
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'mealPlan.name', //access nested data with dot notation
        header: 'Meal Plan',
        size: 150,
      },

      {
        accessorKey: 'name', //normal accessorKey
        header: 'Meal Box name',
        size: 200,
      },
    ],
    []
  );
  //column definitions...

  const table = useMaterialReactTable({
    columns,
    data: scheduleData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    enableFullScreenToggle: true,
    enableRowActions: true,
    positionActionsColumn: 4,
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 160, //default size is usually 180
    },
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {},
    },
    muiTableBodyCellProps: {
      sx: {
        fontWeight: 'normal',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    muiTopToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Delete'>
          <IconButton
            color='error'
            onClick={isPastDate ? null : () => handleDeleteClick(row.original)}
          >
            {isPastDate ? (
              <img src={DeleteIconDisable} alt='Delete' />
            ) : (
              <img src={DeleteIconActive} alt='Delete' />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return <MaterialReactTable table={table} />;
};

export default CreateScheduleTable;
