import { Box, Breadcrumbs, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import SideCalendar from './SideCalendar';
import MainCalendar from './MainCalendar';
import PlannerFooter from '../../../Components/menu_planner/PlannerFooter';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getAllSchedule } from '../schedules/ScheduleApi';
import dayjs from 'dayjs';
import { dateFormatForServer } from '../../../utils/dateUtils';
import { queryKeys } from '../../../utils/queryKeys';
import { useLocation } from 'react-router-dom';

const CalenderScreen = () => {
  const { language } = useSelector((state) => state.globalUi);
  const location = useLocation();
  const [fromDate, setFromDate] = useState(
    dayjs().startOf('month').format(dateFormatForServer)
  );

  const [toDate, setToDate] = useState(
    dayjs().endOf('month').format(dateFormatForServer)
  );

  const [calMonthState, setCalMonthState] = useState(0);

  console.log('Calendar Month State: ' + calMonthState);
  console.log('Calendar FromDate: ' + fromDate);
  console.log('Calendar ToDate: ' + toDate);

  useEffect(() => {
    const updateMonth = () => {
      setFromDate(
        dayjs()
          .add(calMonthState, 'month')
          .startOf('month')
          .format(dateFormatForServer)
      );
      setToDate(
        dayjs()
          .add(calMonthState, 'month')
          .endOf('month')
          .format(dateFormatForServer)
      );
    };
    updateMonth();
  }, [calMonthState]);

  const {
    data: allScheduleData,
    error,
    isLoading,
  } = useQuery(
    [
      queryKeys.getAllScheduleData,
      language,
      fromDate,
      toDate,
      location.state.scheduleType,
    ],
    getAllSchedule
  );

  return (
    <Box>
      <Box
        className='content-horizontal-margin'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
          mt: 3,
          mb: 3,
        }}
      >
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography variant='s16_w500'>Dashboard</Typography>
          <Typography variant='s16_w500_orange'>View Calender</Typography>
        </Breadcrumbs>
        <Box>
          <Typography variant='s24_w600' pt={1}>
            View Calendar
          </Typography>
          <hr
            style={{
              margin: '0 0 0 0',
            }}
          />
        </Box>
        <Grid container>
          <Grid
            item
            xs={5}
            lg={4}
            xl={3}
            sx={{
              border: '1px solid #BDBDBD',
              flexGrow: 1,
            }}
          >
            <SideCalendar />
          </Grid>
          <Grid item xs={7} lg={8} xl={9}>
            <MainCalendar
              calMonthState={calMonthState}
              data={
                allScheduleData?.data !== undefined &&
                allScheduleData?.data?.length > 0
                  ? allScheduleData.data?.flatMap((item) => {
                      return item.scheduledMeals.map((meal) => {
                        return {
                          ...meal,
                          start: item.date,
                          end: item.date,
                          category: 'allday',
                          title: meal.name,
                          calendarId: item?.mealPlan?.name ?? 'Standard',
                        };
                      });
                    })
                  : []
              }
              previousMonthClick={() => setCalMonthState((prev) => prev - 1)}
              nextMonthClick={() => setCalMonthState((prev) => prev + 1)}
              todayClick={() => setCalMonthState(0)}
            />
          </Grid>
        </Grid>
      </Box>
      <PlannerFooter />
    </Box>
  );
};

export default CalenderScreen;
