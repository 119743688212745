import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleMealAction } from '../../../../store/schedule-meal-slice';
const SelectMealPlanItem = ({ selection, mealBoxTypes }) => {
  const dispatch = useDispatch();

  const saveSelection = () => {
    dispatch(ScheduleMealAction.saveSelectedMealBox());
  };
  return (
    <Grid2 container direction='row' alignItems='center'>
      <Grid2 xs={3}>
        <Typography variant='s16_w600' component='span'>
          Select a meal Plan:
        </Typography>
      </Grid2>
      <Grid2 xs={4}>
        <FormControl fullWidth>
          <Select
            value={selection.id ?? ''}
            onChange={(e) => {
              const selectedItem = mealBoxTypes?.find(
                (meal) => e.target.value === meal.id
              );
              console.log('Selection ', selectedItem);
              dispatch(ScheduleMealAction.updateSelection(selectedItem));
            }}
          >
            {mealBoxTypes
              ? mealBoxTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 xs={3} ml={2}>
        <Button variant='contained' onClick={saveSelection}>
          Save
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default SelectMealPlanItem;
