import axios from 'axios';
import { apiPath } from '../../../config';
import { axiosInstance } from '../AxiosInstance';

export const generateEmailOTP = async (email) => {
  axios.defaults.headers.common.Authorization = null;
  return axiosInstance({
    method: 'POST',
    url: apiPath.generateOtp,
    data: {
      email,
    },
  });
};

export const validateEmailOTP = async (email, otp) => {
  console.log('validateEmailOTP', otp);
  return axiosInstance({
    method: 'POST',
    url: apiPath.validateOtp,
    data: {
      email,
      otp,
    },
  });
};

export const getProfileData = async () => {
  return axiosInstance({
    method: 'GET',
    url: apiPath.getProfile,
  });
};
