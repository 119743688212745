import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CartActions } from '../store/cart-slice';

const iconStyle = {
  cursor: 'pointer',
};

const CartAddRemoveComponent = ({ item }) => {
  const { id } = item;
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        border: '1px solid #342C47',
        borderRadius: '10px',
        padding: 1,
        gap: 2,
      }}
    >
      <RemoveIcon
        onClick={() => dispatch(CartActions.removeItemFromCart(id))}
        style={iconStyle}
      />
      <Typography variant='body2'>{item.quantity}</Typography>
      <AddIcon
        onClick={() => dispatch(CartActions.addItemToCart(item))}
        style={iconStyle}
      />
    </Box>
  );
};

export default CartAddRemoveComponent;
