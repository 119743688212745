import { Box, Typography } from '@mui/material';
import React from 'react';
import { menuItems } from '../../../utils/menuUtils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';
import MealItem from '../category_menu_list/MealItem';
const PhoneItemListScreen = ({ title, goBack }) => {
  const meals = useSelector((state) => state.indianCuisineMeal.mealMap);

  console.log('Title: ' + title);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifySelf: 'flex-start',
          minWidth: '100%',
          alignItems: 'center',
          mb: 5,
        }}
      >
        <div onClick={goBack}>
          <ArrowBackIosIcon />
        </div>
        <Typography variant='h6' component='span'>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexGrow: '0',
          flexDirection: 'column',
          maxWidth: '100%',
          gap: 2,
          overflowX: 'auto',
        }}
      >
        {meals[title]?.map((item) => {
          return <MealItem item={item} />;
        })}
      </Box>
    </Box>
  );
};

export default PhoneItemListScreen;
