import React from 'react';
import classes from './CategoryMenuContainer.module.css';
import TitleAndMenuList from './TitleAndMenuList';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { menuItems } from '../../../utils/menuUtils';
const CategoryMenuContainer = () => {
  const meals = useSelector((state) => state.indianCuisineMeal.mealMap);
  return (
    <Box className={classes.container}>
      {menuItems.map((item) => {
        // console.log('Title', item.title);
        return (
          <TitleAndMenuList title={item.title} meals={meals[item.title]} />
        );
      })}
    </Box>
  );
};

export default CategoryMenuContainer;
