import { Box, Breadcrumbs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import LoaderProgressComponent from '../../../../Components/menu_planner/LoaderProgressComponent';
import PlannerFooter from '../../../../Components/menu_planner/PlannerFooter';
import { getAllMealsData } from '../../food_box/create_food_box/CreateFoodBoxApi';
import CreateScheduleItem from './CreateScheduleItem';
import CreateScheduleTopComponent from './CreateScheduleTopComponent';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleMealAction } from '../../../../store/schedule-meal-slice';
import { MealAction } from '../../../../store/meal-slice';
import { queryKeys } from '../../../../utils/queryKeys';
import NoScheduledItemFound from './NoScheduledItemFound';
import CreateScheduleTable from './CreateScheduleTable';
import { getAllSchedule } from '../ScheduleApi';
import { dateFormatForServer } from '../../../../utils/dateUtils';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const filterMeal = (item) =>
  !(item?.isCateringFood || item?.isDefaultLunch || item.isDefaultDinner);
const CreateScheduleScreen = () => {
  const { user } = useSelector((state) => state.auth);
  const { mealList } = useSelector((state) => state.meals);
  const { language } = useSelector((state) => state.globalUi);
  const { date } = useSelector((state) => state.scheduleMeal);
  const { boxType } = useSelector((state) => state.scheduleMeal);
  const dispatch = useDispatch();

  const location = useLocation();
  console.log('CreateScheduleScreen location: ');
  console.dir(location);

  useEffect(() => {
    (function updateDate() {
      //if this is edit mode and date state is available then update the date state in redux.
      if (location.state?.date)
        dispatch(ScheduleMealAction.updateSelectedDate(location.state?.date));
      else
        dispatch(
          ScheduleMealAction.updateSelectedDate(
            dayjs().businessDaysAdd(3).format(dateFormatForServer)
          )
        );
    })();
  }, []);

  //If mealList is empty fetch the meal list.
  const { error, isLoading } = useQuery(
    queryKeys.getMealData,
    getAllMealsData,
    {
      onSuccess: (response) => {
        console.log(queryKeys.getMealData);
        console.dir(response.data);
        dispatch(MealAction.addAllMeals(response.data));
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: allScheduleData } = useQuery(
    [queryKeys.getAllScheduleData, language, date, date, boxType],
    getAllSchedule,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Box>
      <Box className='content-horizontal-margin' mb={5}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 3,
            mt: 3,
            mb: 3,
          }}
        >
          <Breadcrumbs aria-label='breadcrumb'>
            <Typography variant='s16_w500'>Schedule</Typography>
            <Typography variant='s16_w500_orange'>Create a Schedule</Typography>
          </Breadcrumbs>
          <CreateScheduleTopComponent />
          {isLoading ? (
            <LoaderProgressComponent />
          ) : (
            <CreateScheduleItem
              meals={
                //Filter item if it's mealType is 'catering' or isDefaultLunch is true or isDefaultDinner is true
                mealList?.filter(filterMeal) ?? []
              }
              mealsPlan={user?.canteen?.mealPlans}
              // mealsPlan={testMealPlan}
            />
          )}
        </Box>

        {allScheduleData?.data !== undefined &&
        allScheduleData?.data?.length == 0 ? (
          <NoScheduledItemFound />
        ) : (
          <CreateScheduleTable
            scheduleData={
              allScheduleData?.data !== undefined &&
              allScheduleData?.data?.length > 0
                ? allScheduleData.data?.flatMap((item) => {
                    return item.scheduledMeals.map((meal) => {
                      return {
                        ...meal,
                        date: item.date,
                        category: 'allday',
                        title: meal.name,
                        calendarId: item?.mealPlan?.name ?? 'Standard',
                        scheduleType: item?.scheduleType,
                        mealPlan: item?.mealPlan,
                      };
                    });
                  })
                : []
            }
          />
        )}
      </Box>
      <PlannerFooter />
    </Box>
  );
};

export default CreateScheduleScreen;
