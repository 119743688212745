import React from 'react';
import './Experience.css';
import exp1 from './Navdeep.png';
import exp2 from "./shilpi.png";
import exp3 from "./Anika.png";


const Experience = () => {
  return (
		<div className="exp">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12 text-center">
						<h2
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out">
							<span>Our Team</span>  
						</h2>
						<p
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="mt-4">
							The sustainability warriors behind Food Fe. We're a diverse team with a shared passion - making a positive impact through food.
						</p>
					</div>
				</div>
				<div className="row px-5">
					<div className="col-md-4 text-center">
						<img
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="img-fluid"
							src={exp1}
						/>
						<h4
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="mt-3">
							Navdeep Kumar
						</h4>
						<h5
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="mt-3">
							CEO
						</h5>
					</div>
					<div className="col-md-4 text-center">
						<img
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="img-fluid"
							src={exp2}
						/>
						<h4
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="mt-3">
							Shilpi Ghildiyal
						</h4>
						<h5
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="mt-3">
							Operations Head
						</h5>
					</div>
					<div className="col-md-4 text-center">
						<img
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="img-fluid"
							src={exp3}
						/>
						<h4
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="mt-3">
							Anika Sawant
						</h4>
						<h5
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="mt-3">
							Customer Support
						</h5>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Experience