import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  items: [],
};

const checkForPrice = (price) => {
  if (price === undefined || price === null) return 0;
  else return price;
};

const cartSlice = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);
      if (!existingItem) {
        state.items.push({
          ...newItem,
          quantity: 1,
          totalPrice: checkForPrice(newItem.price),
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice += checkForPrice(newItem.price);
      }
    },
    addItemToCartWithQuantity: (state, action) => {
      const { newItem, quantity } = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);
      if (!existingItem) {
        state.items.push({
          ...newItem,
          quantity: +quantity,
          totalPrice: checkForPrice(newItem.price) * quantity,
        });
      } else {
        existingItem.quantity += +quantity;
        existingItem.totalPrice += checkForPrice(newItem.price) * quantity;
      }
    },
    removeItemFromCart: (state, action) => {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      if (existingItem.quantity === 1) {
        //remove the item from the cart
        state.items = state.items.filter((item) => item.id !== id);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice -= checkForPrice(existingItem.price);
      }
    },
    removeAllItemsFromCart: (state, action) => {
      const id = action.payload;
      //remove the item from the cart
      state.items = state.items.filter((item) => item.id !== id);
    },
    clearCart: (state, action) => {
      state.items = [];
    },
    reset: () => initialState,
  },
});

export const CartActions = cartSlice.actions;
export default cartSlice;
