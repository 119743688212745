import React from 'react';
import classes from './MenuHeadCategory.module.css';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import HeaderMenuItem from './HeadMenuItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { menuItems } from '../../../utils/menuUtils';
const rightArrowStyle = {
  color: '#342C47',
  opacity: '0.5',
};
const MenuHeadCategory = ({ mealTags }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: {
            xs: 'center',
            md: 'left',
          },
        }}
      >
        <Typography variant='h3' component='span' color='primary'>
          Indian
        </Typography>
        &nbsp;
        <Typography variant='h3' component='span' color='secondary'>
          Cuisine
        </Typography>
      </Box>
      {!matches ? (
        <div className={classes.cuisineContainer}>
          <Typography variant='body1'>
            Relish the timeless classics and regional favorites from{' '}
            <strong>India</strong>, as our menu brings home the essence of
            India's culinary diversity
          </Typography>
          <div className={classes.headMenuContainer}>
            {menuItems.map((item) => (
              <HeaderMenuItem title={item.title} imgPath={item.url} />
            ))}
          </div>
          <div className={classes.arrow}>
            <ChevronRightIcon fontSize='large' sx={rightArrowStyle} />
          </div>
        </div>
      ) : (
        <Typography variant='body1' textAlign='center'>
          Relish the timeless classics and regional favorites from{' '}
          <strong>India</strong>, as our menu brings home the essence of India's
          culinary diversity
        </Typography>
      )}
      <div className={classes.noteLayout}>
        <Typography variant='body1' color='secondary' textAlign='center'>
          Place your orders at least <strong>3 days</strong> in advance. For any
          specific requirements or queries, reach out to us at{' '}
          <strong>46 389 345</strong>.
        </Typography>
      </div>
    </div>
  );
};

export default MenuHeadCategory;
