import { Box, Typography } from '@mui/material';
import React from 'react';
import { menuItems } from '../../../utils/menuUtils';
import PhoneMenuHeadCategory from '../menu_head_category/PhoneMenuHeadCategory';

const PhoneMenuScreen = ({ handleOnItemClick }) => {
  return (
    <>
      <PhoneMenuHeadCategory />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 4,
        }}
      >
        {menuItems.map((menuItem) => (
          <Box
            onClick={() => handleOnItemClick(menuItem.title)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexShrink: 0,
              }}
            >
              <img
                src={menuItem.land_url}
                alt={menuItem.title}
                style={{
                  maxWidth: '33.6rem',
                  borderRadius: '1em',
                  background: '#D9D9D9',
                  overflow: 'hidden',
                }}
              />
            </Box>

            <Typography variant='body1'>{menuItem.title}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default PhoneMenuScreen;
