import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ScheduleMealAction } from '../../../../store/schedule-meal-slice';

const CreateScheduleTopComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleViewAllScheduleClick = () => {
    dispatch(ScheduleMealAction.reset());
    navigate(-1);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
        }}
      >
        <Typography variant='s24_w600' color='primary'>
          Schedule
        </Typography>
      </Box>
      <Button variant='outlined' onClick={handleViewAllScheduleClick}>
        View all schedule
      </Button>
    </Box>
  );
};

export default CreateScheduleTopComponent;
