import { configureStore } from '@reduxjs/toolkit';
import cartSlice from './cart-slice';
import mealSlice from './meal-slice';
import authSlice from './auth-slice';
import createMealSlice from './create-meal-slice';
import scheduleMealSlice from './schedule-meal-slice';
import globalUiSlice from './global-ui-slice';
import createCalendarSlice from './calander-slice';
import boxSummarySlice from './box-summary-slice';
import createScheduleSummarySlice from './schedule-summary-slice';
import indianCuisineMealSlice from './indian-cuisine-meal-slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    cart: cartSlice.reducer,
    meals: mealSlice.reducer,
    indianCuisineMeal: indianCuisineMealSlice.reducer,
    createMeal: createMealSlice.reducer,
    scheduleSummary: createScheduleSummarySlice.reducer,
    scheduleMeal: scheduleMealSlice.reducer,
    globalUi: globalUiSlice.reducer,
    calander: createCalendarSlice.reducer,
    boxSummary: boxSummarySlice.reducer,
  },
});

export default store;
