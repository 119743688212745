import {
  Box,
  Divider,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../Components/layout/footer/Footer';
import Header from '../../Components/layout/header/Header';
import { containerWidth } from '../../utils/dimentions';
import MobileCartScreen from './MobileCartScreen';
import OrderDetail from './OrderDetail';
import CartHolder from './cart_info/CartHolder';
import CartLocationMap from './location_map/CartLocationMap';

const CartScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const cartItems = useSelector((state) => state.cart.items);
  const totalPrice = cartItems.reduce(
    (totalPrice, item) => totalPrice + item.totalPrice,
    0
  );
  const cartItemsCount = cartItems === undefined ? 0 : cartItems.length;
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  let desktopScreen = (
    <Grid container columnGap={'20px'} wrap='nowrap' mt={8}>
      <Grid item xs={8}>
        <OrderDetail />
      </Grid>
      <Grid item xs={4}>
        <CartHolder isCheckoutScreen={true} />
      </Grid>
    </Grid>
  );

  let mobileScreen = <MobileCartScreen />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: containerWidth,
        margin: 'auto',
      }}
    >
      <Header />
      <Divider />
      <CartLocationMap />
      {matches ? mobileScreen : desktopScreen}
      <Footer />
    </Box>
  );
};

export default CartScreen;
