const diwaliSpecial =
  process.env.PUBLIC_URL + '/assets/indian_cusuines/diwali_special.png';
const landDiwaliSpecial =
  process.env.PUBLIC_URL +
  '/assets/indian_cusuines_large/large_diwali_special.png';
const snacks = process.env.PUBLIC_URL + '/assets/indian_cusuines/snacks.png';
const landSnacks =
  process.env.PUBLIC_URL + '/assets/indian_cusuines_large/large_snacks.png';

const curries = process.env.PUBLIC_URL + '/assets/indian_cusuines/curries.png';
const landCurries =
  process.env.PUBLIC_URL + '/assets/indian_cusuines_large/large_curries.png';

const rice_n_rotis =
  process.env.PUBLIC_URL + '/assets/indian_cusuines/rice_n_rotis.png';
const landRiceNRotis =
  process.env.PUBLIC_URL +
  '/assets/indian_cusuines_large/large_rice_n_rotis.png';

const deserts = process.env.PUBLIC_URL + '/assets/indian_cusuines/desserts.png';
const landDesserts =
  process.env.PUBLIC_URL + '/assets/indian_cusuines_large/large_desserts.png';

//   Diwali Special
// Curries
// Snacks
// Rice & Rotis
// Desserts
export const menuItems = [
  {
    title: 'Diwali Special',
    url: diwaliSpecial,
    land_url: landDiwaliSpecial,
  },
  {
    title: 'Snacks',
    url: snacks,
    land_url: landSnacks,
  },
  {
    title: 'Curries',
    url: curries,
    land_url: landCurries,
  },
  {
    title: 'Rice & Rotis',
    url: rice_n_rotis,
    land_url: landRiceNRotis,
  },
  {
    title: 'Desserts',
    url: deserts,
    land_url: landDesserts,
  },
];
