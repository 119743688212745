export const containerWidth = {
  xl: '60vw',
  lg: '90vw',
  md: '100vw',
};

export const layoutWidth = {
  xl: '60vw',
  lg: '90vw',
  xs: '95vw',
};
