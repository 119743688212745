import { menuItems } from '../../utils/menuUtils';

export const getMealMapWithCategoryFromMealList = (meals) => {
  // Create an empty Object
  let mealsMap = {};
  meals.forEach((item) => {
    if (item.tags.length > 0) {
      //Iterate through the tag and check for all tags
      //If there is no tag already in the array
      // create new array
      // otherwise push to existing array
      item.tags.forEach((tag) => {
        if (menuItems.find((searchItem) => searchItem.title === tag)) {
          mealsMap[tag] = mealsMap[tag] || [];
          mealsMap[tag].push(item);
        }
      });
    }
  });

  //   Object.keys(mealMap).forEach((key) => {
  //     const value = mealMap[key];
  //     console.log(`${key}:`);
  //     console.dir(value);
  //   });
  return mealsMap;
};
