const Poultry = process.env.PUBLIC_URL + '/assets/meat-types/chicken.png';
const Beef = process.env.PUBLIC_URL + '/assets/meat-types/beef.png';
const Fish = process.env.PUBLIC_URL + '/assets/meat-types/fish.png';
const Vegan = process.env.PUBLIC_URL + '/assets/meat-types/vegan.png';
const Pork = process.env.PUBLIC_URL + '/assets/meat-types/pork.png';
const Deer = process.env.PUBLIC_URL + '/assets/meat-types/deer.png';

export const getMeatTypeIconsUrl = (meatType) => {
  switch (meatType) {
    case 'Poultry':
      return Poultry;
    case 'Pork':
      return Pork;
    case 'Mutton':
    case 'Venison':
      return Deer;
    case 'Fish':
      return Fish;
    case 'NotApplicable':
      return Vegan;
    case 'Beef':
      return Beef;
    default:
      return Vegan;
  }
};
