import React from 'react'
import './ButHow.css';
import Veggie from './ButHow.png'

export const ButHow = () => {
  return (
		<div id="ButHow" className="ButHow">
			<div className="container">
				<div className="row">
					<div className="col-md-6 d-flex align-items-center justify-content-center">
						<div className="leftText">
							<h3
								data-aos="fade-up"
								data-aos-duration="800"
								data-aos-easing="ease-in-out">
								But How<span>, You Ask?</span>
							</h3>
							<p
								data-aos="fade-up"
								data-aos-duration="800"
								data-aos-easing="ease-in-out"
								className="mt-4">
								By pairing our tech prowess with a love for diverse cuisines, we
								minimize waste and bring you meals that sing songs of culture,
								quality, and health. This isn't just food delivery - it's a
								tech-fuelled culinary experience crafted around your needs.
							</p>
						</div>
					</div>
					<div className="col-md-6">
						<img
							data-aos="fade-left"
							data-aos-delay="500"
							data-aos-duration="1000"
							data-aos-easing="ease-in-out"
							src={Veggie}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
