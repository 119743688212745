import { Box, Breadcrumbs, Stack, Typography } from '@mui/material';
import React from 'react';
import DashboardTopComponent from './DashboardTopComponent';
import Example from '../schedules/Example';

const DashboardScreen = () => {
  return (
    // <Example />
    <Box>
      <Box
        className='content-horizontal-margin'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
          mt: 3,
          mb: 3,
        }}
      >
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography variant='s16_w500_orange'>Dashboard</Typography>
        </Breadcrumbs>
        <DashboardTopComponent />
        <Stack display='flex' justifyContent='center' alignItems='center'>
          <Typography
            variant='s24_w600'
            color='secondary'
            component='span'
            p={5}
          >
            In Development
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default DashboardScreen;
