import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../Screens/menu_planner/AxiosInstance';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { AuthActions } from '../../../store/auth-slice';

const ProfileMenu = ({ navStyle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type) => {
    setAnchorEl(null);

    switch (type) {
      case 'profile': {
        if (!location.pathname.includes('/meal-planner/profile'))
          navigate('/meal-planner/profile');
        break;
      }
      case 'logout': {
        console.log('logout');
        localStorage.clear();
        navigate('/meal-planner/', { replace: true });
        axiosInstance.defaults.headers.common.Authorization = null;
        dispatch(AuthActions.logout());
        break;
      }
      default: {
      }
    }
  };

  return (
    <>
      <Button className={navStyle} onClick={handleClick}>
        <Box
          component='img'
          src={process.env.PUBLIC_URL + '/assets/menu_planner/user-circle.svg'}
          alt='Profile'
          sx={{
            height: '3.2rem',
            width: '3.2rem',
          }}
        />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClose('profile')}>Profile</MenuItem>
        <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
