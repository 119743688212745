import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';
const DeleteIcon = process.env.PUBLIC_URL + '/assets/menu_planner/delete.svg';

const IngredientItemComponent = ({ item, type }) => {
  const dispatch = useDispatch();
  return (
    <Grid2
      container
      direction='row'
      alignItems='center'
      justifyContent='stretch'
    >
      <Grid2
        xs={8}
        sx={{
          border: '1px solid #BDBDBD',
          borderRadius: '5px',
          p: 2,
        }}
      >
        <Typography variant='s16_w400'>{`${item.english}/${item.nor}`}</Typography>
      </Grid2>
      <Grid2 xs={1} ml={5}>
        <Box
          component='img'
          src={DeleteIcon}
          alt='Delete Icon'
          onClick={() =>
            dispatch(CreateMealAction.deleteItem({ type, data: item }))
          }
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default IngredientItemComponent;
