import { Box, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { navRoute } from '../../../utils/navInfo';

const CalendarMenu = ({ mealsPlan }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type) => {
    setAnchorEl(null);

    switch (type) {
      case 'lunch': {
        navigate(navRoute.navigateToCalendar, {
          state: {
            scheduleType: 'Lunch',
          },
        });
        break;
      }
      case 'dinner': {
        navigate(navRoute.navigateToCalendar, {
          state: {
            scheduleType: 'Dinner',
          },
        });
        break;
      }
      default: {
      }
    }
  };

  return (
    <>
      <Box
        variant='s16_w600'
        onClick={handleClick}
        sx={{
          padding: '10px 0',
          borderBottom: location.pathname.includes('calendar')
            ? '3px solid #f5835e'
            : '3px solid transparent',
          ':hover': {
            cursor: 'pointer',
            borderBottom: '3px solid #f5835e',
          },
        }}
      >
        Calendar
      </Box>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {mealsPlan?.Lunch && mealsPlan?.Lunch?.length > 0 ? (
          <MenuItem onClick={() => handleClose('lunch')}>Lunch</MenuItem>
        ) : null}
        {mealsPlan?.Dinner && mealsPlan?.Dinner?.length > 0 ? (
          <MenuItem onClick={() => handleClose('dinner')}>Dinner</MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default CalendarMenu;
