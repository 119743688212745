import { Box, Breadcrumbs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import FoodBoxTopComponent from './FoodBoxTopComponent';
import ActionComponent from '../../components/ActionComponent';
import PlannerFooter from '../../../../Components/menu_planner/PlannerFooter';
import FoodBoxGridList from './FoodBoxGridList';
import { getAllMealsData } from '../create_food_box/CreateFoodBoxApi';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import LoaderProgressComponent from '../../../../Components/menu_planner/LoaderProgressComponent';
import FoodBoxTable from './FoodBoxTable';
import FoodBoxSummaryActionComponent from './FoodBoxSummaryActionComponent';
import SummarySearchComponent from './SummarySearchComponent';
import { MealAction } from '../../../../store/meal-slice';
import { queryKeys } from '../../../../utils/queryKeys';

const FoodBoxScreen = () => {
  const { language } = useSelector((state) => state.globalUi);

  const { isViewAsTable } = useSelector((state) => state.boxSummary);
  const dispatch = useDispatch();
  const { error, isLoading } = useQuery(
    queryKeys.getMealData,
    getAllMealsData,
    {
      onSuccess: (response) => {
        console.log(queryKeys.getMealData);
        console.dir(response.data);
        dispatch(MealAction.addAllMeals(response.data));
      },
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000, //Milliseconds
    }
  );

  useEffect(() => {
    (() => {
      dispatch(MealAction.reset());
    })();
  }, []);

  return (
    <Box>
      <Box
        className='content-horizontal-margin'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
          mt: 3,
          mb: 3,
        }}
      >
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography variant='s16_w500_orange'>Food Boxes</Typography>
        </Breadcrumbs>
        <FoodBoxTopComponent />
        <FoodBoxSummaryActionComponent />
        {!isViewAsTable ? <SummarySearchComponent /> : null}
        {isLoading ? (
          <LoaderProgressComponent />
        ) : isViewAsTable ? (
          <FoodBoxTable />
        ) : (
          <FoodBoxGridList />
        )}
      </Box>
      <PlannerFooter />
    </Box>
  );
};

export default FoodBoxScreen;
