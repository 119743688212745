import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Switch, Tooltip, styled } from '@mui/material';
import { patchMealBox } from '../food_box/create_food_box/CreateFoodBoxApi';
import toast from 'react-hot-toast';
import { queryKeys } from '../../../utils/queryKeys';
import { useMutation, useQueryClient } from 'react-query';

const EditIcon = process.env.PUBLIC_URL + '/assets/menu_planner/edit_icon.svg';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const filterDailyMeal = (item, isDefaultLunch) =>
  isDefaultLunch ? item?.isDefaultLunch : item.isDefaultDinner;

const DailyMealScheduleTable = ({ isDefaultLunch = true }) => {
  const { mealList } = useSelector((state) => state.meals);
  const { language } = useSelector((state) => state.globalUi);
  const [data, setData] = useState([]);
  const queryClient = useQueryClient();
  let toastId;
  useEffect(() => {
    (function () {
      setData(
        mealList?.filter((item) => filterDailyMeal(item, isDefaultLunch))
      );
    })();
  }, [language, mealList]);

  const handleScheduleClick = (schedule) => {
    console.log(schedule);
    // navigate()
  };

  const handleDailyfareMealBoxUpdate = (externalId, isChecked) => {
    toastId = toast.loading('Processing...');
    patchDailyFareMealBox({
      externalId: externalId,
      isDisabled: isChecked,
    });
  };

  const { mutate: patchDailyFareMealBox } = useMutation(
    (param) => patchMealBox(param),
    {
      onSuccess: () => {
        toast.dismiss(toastId);
        queryClient.invalidateQueries({ queryKey: [queryKeys.getMealData] });
        toast.success('Meal updated successfully');
      },
      onError: (err) => {
        console.log('handleErr');
        toast.dismiss(toastId);
        try {
          toast.error(err.response.data.message);
        } catch (err) {
          toast.error('Something went wrong!');
        }
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        header: 'Meal Plan',
        size: 150,
        accessorFn: () => (isDefaultLunch ? 'Lunch' : 'Dinner'),
      },
      {
        header: 'Meal Box name',
        size: 150,
        accessorFn: (row) =>
          language === 'English' ? row.details[0].name : row.details[1].name,
      },
    ],
    []
  );
  //column definitions...

  const table = useMaterialReactTable({
    columns,
    data: data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    enableFullScreenToggle: true,
    enableRowActions: true,
    positionActionsColumn: 4,
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),

    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {},
    },
    muiTableBodyCellProps: {
      sx: {
        fontWeight: 'normal',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    muiTopToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    renderRowActions: ({ row, table }) => {
      // console.log('renderRowActions', row.original.isDisabled);
      return (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title='Edit'>
            <IconButton onClick={() => handleScheduleClick(row.original)}>
              <AntSwitch
                checked={!row.original.isDisabled}
                inputProps={{ 'aria-label': 'ant design' }}
                onChange={(e) =>
                  handleDailyfareMealBoxUpdate(
                    row.original.externalId,
                    !e.target.checked
                  )
                }
              />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip> */}
        </Box>
      );
    },
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return <MaterialReactTable table={table} />;
};

export default DailyMealScheduleTable;
