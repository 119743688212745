import React, { useEffect } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarAction } from '../../../store/calander-slice';
import { disableWeekends } from '../../../utils/utils';
import { useLocation } from 'react-router-dom';

const circleSx = {
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  border: '1.2px solid #342C47',
};

const calanderViewTypexs = {
  border: '1px solid #BDBDBD',
  padding: '6.5px 10px',
  borderRadius: '5px',
  ':hover': { cursor: 'pointer' },
};

const SideCalendar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    showAll,
    standard,
    veg,
    halal,
    show7dayWeek,
    calendarView,
    ['Premium Lunsj']: premiumLunsj,
    ['Premium Middag']: premiumMiddag,
  } = useSelector((state) => state.calander);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    (function () {
      dispatch(
        CalendarAction.updateKitchen(
          user.canteen.kitchens.includes('Bergen') ? 'Bergen' : 'Clkfdjs'
        )
      );
    })();
  }, []);
  return (
    <Stack>
      <Stack
        direction='row'
        justifyContent='space-around'
        p={2}
        borderBottom='1px solid #BDBDBD'
        alignItems='center'
      >
        <Box
          sx={
            calendarView === 'day'
              ? calanderViewTypexs
              : { ':hover': { cursor: 'pointer' } }
          }
          onClick={() => dispatch(CalendarAction.updateCalendarView('day'))}
        >
          <Typography component='span'>Day</Typography>
        </Box>
        <Box
          sx={
            calendarView === 'week'
              ? calanderViewTypexs
              : { ':hover': { cursor: 'pointer' } }
          }
          onClick={() => dispatch(CalendarAction.updateCalendarView('week'))}
        >
          <Typography component='span'>Week</Typography>
        </Box>
        <Box
          sx={
            calendarView === 'month'
              ? calanderViewTypexs
              : { ':hover': { cursor: 'pointer' } }
          }
          onClick={() => dispatch(CalendarAction.updateCalendarView('month'))}
        >
          <Typography component='span'>Month</Typography>
        </Box>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar views={['day']} shouldDisableDate={disableWeekends} />
      </LocalizationProvider>
      <hr />
      <FormControlLabel
        sx={{ padding: '0 16px' }}
        control={
          <Checkbox
            checked={show7dayWeek}
            onChange={() => dispatch(CalendarAction.updateShow7DayWeek())}
          />
        }
        label='Show 7 - days week'
      />
      <hr />
      <Stack p='0 16px' mt={4} mb={4}>
        <Typography>Filter Calendar</Typography>
        <FormGroup sx={{ mt: 2 }}>
          {user?.canteen?.kitchens?.includes('Bergen') ? null : (
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAll}
                  onChange={() =>
                    dispatch(CalendarAction.updateShowAllCheckbox())
                  }
                />
              }
              label='Show all'
            />
          )}
          {user?.canteen?.kitchens?.includes('Bergen') ? (
            <Stack width='80%'>
              {location.state.scheduleType === 'Lunch' ? (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={premiumLunsj}
                        onChange={() =>
                          dispatch(CalendarAction.updatePremiumLunsjCheckbox())
                        }
                      />
                    }
                    label='Premium Lunsj'
                  />
                  <Box sx={{ ...circleSx, background: '#007AFF1A' }} />
                </Stack>
              ) : (
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={premiumMiddag}
                        onChange={() =>
                          dispatch(CalendarAction.updatePremiumMiddagCheckbox())
                        }
                      />
                    }
                    label='Premium Middag'
                  />
                  <Box sx={{ ...circleSx, background: '#46B52A33' }} />
                </Stack>
              )}
            </Stack>
          ) : (
            <Stack width='80%'>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={standard}
                      onChange={() =>
                        dispatch(CalendarAction.updateStandardCheckbox())
                      }
                    />
                  }
                  label='Standard'
                />
                <Box sx={{ ...circleSx, background: '#007AFF1A' }} />
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={veg}
                      onChange={() =>
                        dispatch(CalendarAction.updateVegCheckbox())
                      }
                    />
                  }
                  label='Veg'
                />
                <Box sx={{ ...circleSx, background: '#46B52A33' }} />
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={halal}
                      onChange={() =>
                        dispatch(CalendarAction.updateHalalCheckbox())
                      }
                    />
                  }
                  label='Halal'
                />
                <Box sx={{ ...circleSx, background: '#F5825E33' }} />
              </Stack>
            </Stack>
          )}
        </FormGroup>
      </Stack>
    </Stack>
  );
};

export default SideCalendar;
