import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const TestScreen = () => {
  const navigate = useNavigate();
  const handleClickSuccess = () => {
    navigate('/meal-planner/food-boxes/food-box-created-successfully', {
      state: {
        externalId: 'f72119c7-0237-400a-9dd0-a6d66abedcc7',
        caloriesPerKg: 32,
        quantity: 43,
        quantityUnit: 'grams',
        isHalal: false,
        isVeg: true,
        isCateringFood: false,
        meatType: 'NotApplicable',
        isDefaultLunch: false,
        isDefaultDinner: false,
        images: [
          'https://cdn.dev.dcx.no/foodfe/1dd0b345-bcc1-4ea3-86b0-02281af416a2/LPD-964.jpeg',
        ],
        thumbnails: [
          'https://cdn.dev.dcx.no/foodfe/1dd0b345-bcc1-4ea3-86b0-02281af416a2/LPD-964-thumbnail-240.jpeg',
        ],
        allergies: ['a047Y000007nGdhQAE', 'a047Y000007nGdzQAE'],
        details: [
          {
            language: 'English',
            name: 'Kiran Catering Food',
            description: 'Kiran Catering Food',
            contents: 'chicken',
            tags: null,
          },
          {
            language: 'Norwegian',
            name: 'Kiran Catering Food 1',
            description: 'Kiran Catering Food',
            contents: 'spicy',
            tags: null,
          },
        ],
      },
    });
  };
  return (
    <Button variant='contained' onClick={handleClickSuccess}>
      Create Meal Success
    </Button>
  );
};

export default TestScreen;
