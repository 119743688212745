import React from 'react';
import './About.css';
import { Box } from '@mui/material';

export const About = () => {
  return (
    <Box>
      <div id='about' className='about'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <h2
                data-aos='fade-up'
                data-aos-duration='800'
                data-aos-easing='ease-in-out'
              >
                About <span>Us</span>
              </h2>
              <p
                data-aos='fade-up'
                data-aos-duration='800'
                data-aos-easing='ease-in-out'
                className='mt-4'
              >
                {' '}
                Food Fe is more than just a food-tech platform; it's a mission
                to make a positive impact on society and our environment. Our
                journey started with a vision to create a sustainable food
                future and has grown into a movement that supports immigrants,
                battles loneliness, and promotes community bonding through food.
                We aren't merely running a business; we're stirring up a
                revolution, one meal at a time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
