import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { forwardRef, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SelectedFoodItem from './SelectedFoodItem';
import AddNewFoodIngredientItemDialog from './AddNewFoodIngredientItemDialog';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import IngredientItemComponent from './IngredientItemComponent';
import AddNewTempFoodIngredientItemDialog from './AddNewTempFoodIngredientItemDialog';
import { useSelector } from 'react-redux';

const NoItemAvailable = () => (
  <Stack display='flex' flexDirection='column' justifyContent='center' p={4}>
    <Typography variant='s18_w500'>No Item Added</Typography>
  </Stack>
);

const FoodBoxTempIngredientsSection = forwardRef((props, ref) => {
  const { primaryIngredient, additionalIngredient } = useSelector(
    (state) => state.createMeal
  );

  return (
    <Stack
      ref={ref}
      sx={{
        rowGap: 3,
        p: 3,
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='s20_w600'>Food Box Ingredients</Typography>
        <Stack direction='row' alignItems='center' columnGap={1}>
          <ErrorOutlineIcon
            style={{ color: '#F5825E' }}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
          />
          <Typography
            variant='s14_w500'
            sx={{
              color: '#F5825E',
              textDecoration: 'underline',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            Learn more about food items
          </Typography>
        </Stack>
      </Stack>
      <hr />
      <Grid2 container spacing={2}>
        <Grid2
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1,
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant='s16_w500' component='span'>
              Primary Ingredient*
            </Typography>
            <AddNewTempFoodIngredientItemDialog
              variant='contained'
              type='primary'
            />
          </Stack>
          {primaryIngredient.length > 0 ? (
            primaryIngredient?.map((item) => (
              <IngredientItemComponent item={item} type='primary' />
            ))
          ) : (
            <NoItemAvailable />
          )}
        </Grid2>

        {/* <Grid2
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant='s16_w500' component='span'>
              Additional Ingredient
            </Typography>
            <AddNewTempFoodIngredientItemDialog
              variant='outlined'
              type='additional'
            />
          </Stack>
          {additionalIngredient.length > 0 ? (
            additionalIngredient?.map((item) => (
              <IngredientItemComponent item={item} type='additional' />
            ))
          ) : (
            <NoItemAvailable />
          )}
        </Grid2> */}
      </Grid2>
    </Stack>
  );
});

export default FoodBoxTempIngredientsSection;
