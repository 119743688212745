import { createSlice } from '@reduxjs/toolkit';

const initState = {
  token: localStorage.getItem('token'),
  user: JSON.parse(localStorage.getItem('user')) ?? '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    saveAuthToken: (state, action) => {
      const { user, token } = action.payload;
      state.token = token;
      state.user = user;
    },
    logout: (state) => {
      localStorage.clear();
      state.token = null;
      state.user = null;
    },
  },
});

export const AuthActions = authSlice.actions;
export default authSlice;
