import { Box, Button, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import CartLocationMap from '../cart/location_map/CartLocationMap';
import Footer from '../../Components/layout/footer/Footer';
import classes from './OrderPlacedScreen.module.css';
import { useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { containerWidth } from '../../utils/dimentions';
import Header from '../../Components/layout/header/Header';
const OrderPlacedScreen = () => {
  const navigate = useNavigate();
  const handleExploreNewMenuBtn = () => {
    navigate('/indiancuisine', {
      replace: true,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: containerWidth,
        margin: 'auto',
      }}
    >
      <Header />
      <Divider />
      <CartLocationMap />
      <div className={classes.infoContainer}>
        <Typography variant='h3'>Your order details</Typography>
        <div className={classes.infoText}>
          <Typography variant='body1' component='span'>
            Your order has been placed
          </Typography>
          &nbsp;
          <Typography variant='h5' component='span' color='primary'>
            successfully!
          </Typography>
        </div>
        <Button
          variant='contained'
          onClick={handleExploreNewMenuBtn}
          sx={{
            mt: { xs: 10, md: 0 },
          }}
        >
          Explore New Menus
        </Button>
        <div className={classes.socialContainer}>
          <Typography variant='h5' color='secondary'>
            Follow us on
          </Typography>
          <div className={classes.socialItems}>
            <div className={classes.cursorPointer}>
              <InstagramIcon fontSize='large' color='primary' />
            </div>
            <div className={classes.cursorPointer}>
              <FacebookIcon fontSize='large' color='primary' />
            </div>
            <div className={classes.cursorPointer}>
              <LinkedInIcon fontSize='large' color='primary' />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Box>
  );
};

export default OrderPlacedScreen;
