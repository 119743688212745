import { Box } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const CloseButtonComponent = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        borderRadius: '32px',
        padding: '6px',
        cursor: 'pointer',
        backgroundColor: '#342C47',
      }}
      onClick={props.onClose}
    >
      <CloseIcon fontSize='medium' color='white' />
    </Box>
  );
};

export default CloseButtonComponent;
