import React from 'react';
import classes from './Footer.module.css';
import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
const Footer = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const handleNavigationToMenu = () => {
    if (location.pathname.includes('indiancuisine')) return;
    navigate('/indiancuisine');
  };

  return (
    <div className={classes.container}>
      <Typography variant='h4_v2'>
        By joining the Food Fe family, you're supporting a sustainable food
        future. It's good food that does good!
      </Typography>
      <Button
        variant='contained'
        onClick={handleNavigationToMenu}
        color='secondary'
      >
        Our Menu
      </Button>
    </div>
  );
};

export default Footer;
