import React, { useEffect, useState } from 'react';
import classes from './MealItem.module.css';
import { Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { CartActions } from '../../../store/cart-slice';
const MealItem = ({ item }) => {
  const cartInfo = useSelector((state) => state.cart.items);
  const ItemInCartInfo = cartInfo.find((cartItem) => cartItem.id === item.id);
  // console.log('ItemInCartInfo');
  // console.log(ItemInCartInfo);
  const dispatch = useDispatch();

  const handleOnAddCount = () => dispatch(CartActions.addItemToCart(item));
  const handleOnRemoveCount = () => {
    if (ItemInCartInfo !== undefined)
      dispatch(CartActions.removeItemFromCart(item.id));
  };

  return (
    <div className={classes.container}>
      <div>
        <img
          src={item.thumbnailUrls[0]}
          alt={item.name}
          className={classes.img}
        />
        <div className={classes.mealInfo}>
          <div>
            <Typography variant='h5' mb='4px'>
              {item.name}
            </Typography>
            <Typography variant='body1'>
              {item?.portionSize === undefined ? '0' : item.portionSize}
            </Typography>
          </div>
          <Typography variant='body1' color='#6C6C6C'>
            {item.description}
          </Typography>
          <div>
            <Typography variant='body1' mb='4px'>
              Allergens : &nbsp;
            </Typography>
            <Typography variant='body1' color='#6C6C6C'>
              {item?.allergies.map((item, index) => {
                return index === item.allergies?.length - 1
                  ? item
                  : `${item}, `;
              })}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.priceNButton}>
        <Typography variant='body1' ml={3}>
          {`${item.price} NOK`}
        </Typography>
        <div className={classes.buttonContainer}>
          <div onClick={handleOnAddCount} className={classes.button}>
            <AddCircleOutlineIcon fontSize='large' color='white' />
          </div>
          <Typography variant='body1' color='#fff'>
            {ItemInCartInfo === undefined
              ? '00'
              : ItemInCartInfo?.quantity?.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
          </Typography>
          <div onClick={handleOnRemoveCount} className={classes.button}>
            <RemoveCircleOutlineIcon fontSize='large' color='white' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealItem;
