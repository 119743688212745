import React from 'react'
import './Sustain.css'
import sustainSlider1 from './sustain-1.png'

export const Sustain = () => {
  return (
		<div id="sustain" className="sustain">
			<div className="container">
				<div className="row">
					<div className="col-md-6 d-flex align-items-center justify-content-center">
						<div className="leftText">
							<h2
								data-aos="fade-up"
								data-aos-duration="800"
								data-aos-easing="ease-in-out">
								Sustainability:
								<br /> <span>The Heart and Soul of Who We Are.</span>
							</h2>
							<h3
								data-aos="fade-up"
								data-aos-duration="800"
								data-aos-easing="ease-in-out"
								className="mt-5">
								Tech Forward
							</h3>
							<p
								data-aos="fade-up"
								data-aos-duration="800"
								data-aos-easing="ease-in-out"
								className="mt-4">
								Our tech-powered approach enables us to cook precisely what's
								needed, keeping waste at a bare minimum.
							</p>
						</div>
					</div>
					<div className="col-md-6">
						<img
							data-aos="fade-left"
							data-aos-delay="500"
							data-aos-duration="1000"
							style={{ height: "415px" }}
							src={sustainSlider1}
							className="img-fluid"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
