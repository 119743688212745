import React from 'react';
import classes from './LocationMap.module.css';
import { Icon, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const rightArrowStyle = {
  color: '#342C47',
  opacity: '0.5',
};
const LocationMap = () => {
  return (
    <div className={classes.container}>
      <Typography
        color='secondary'
        variant='subtitle2'
        sx={{
          textDecoration: 'underline',
        }}
      >
        Home
      </Typography>
      <ChevronRightIcon fontSize='large' sx={rightArrowStyle} />

      <Typography
        variant='subtitle2'
        color='secondary'
        sx={{
          textDecoration: 'underline',
        }}
      >
        Menu
      </Typography>
      <ChevronRightIcon fontSize='large' sx={rightArrowStyle} />

      <Typography variant='subtitle2' color='#6C6C6C'>
        Indian Cuisine
      </Typography>
    </div>
  );
};

export default LocationMap;
