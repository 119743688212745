import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { dateFormatForServer } from '../utils/dateUtils';
import dayjsBusinessDays from 'dayjs-business-days';
dayjs.extend(dayjsBusinessDays);

const initialState = {
  showAddMore: false,
  boxType: 'Lunch',
  mealBoxTypes: [],
  selectedBoxType: [],
  selection: {},
  date: dayjs().businessDaysAdd(3).format(dateFormatForServer),
};

const scheduleMealSlice = createSlice({
  name: 'schedule-meal',
  initialState,
  reducers: {
    saveSelectedMealBox: (state, action) => {
      state.selectedBoxType.push(state.selection);
      state.mealBoxTypes = state.mealBoxTypes.filter(
        (m) => m.id !== state.selection.id
      );
      state.showAddMore = state.mealBoxTypes.length !== 0;
      //Set default selection
      state.selection =
        state.mealBoxTypes.length > 0 ? state.mealBoxTypes[0] : {};
    },
    deleteSelectedMealBox: (state, action) => {
      const selectedItem = action.payload;
      state.selectedBoxType = state.selectedBoxType.filter(
        (m) => m.id !== selectedItem.id
      );
      state.mealBoxTypes.push(action.payload);
      state.showAddMore = state.selectedBoxType.length > 0;
      //Set default selection
      state.selection =
        state.mealBoxTypes.length > 0 ? state.mealBoxTypes[0] : {};
    },
    addMoreItem: (state, action) => {
      state.showAddMore = false;
    },
    updateSelection: (state, action) => {
      state.selection = action.payload;
    },
    reset: () => {
      return initialState;
    },
    setMealBoxTypes: (state, action) => {
      console.log('setMealBoxTypes', action.payload);
      //when data is set first when screen is opened, filter the list having scheduleType='lunch' and isNoMeal = false
      const mealsPlan = action.payload;
      if (state.boxType === mealsPlan.Lunch) {
        const scheduleTypes = mealsPlan.Lunch?.filter((m) => !m.isNoMeal);
        state.mealBoxTypes = scheduleTypes;
        state.boxType = 'Lunch';
      } else {
        const scheduleTypes = mealsPlan.Dinner?.filter((m) => !m.isNoMeal);
        state.mealBoxTypes = scheduleTypes;
        state.boxType = 'Dinner';
      }

      state.showAddMore = false;
      state.selectedBoxType = [];
      state.selection =
        state.mealBoxTypes?.length > 0 ? state.mealBoxTypes[0] : {};
    },
    updateBoxType: (state, action) => {
      const { boxType, mealBoxType } = action.payload;
      const scheduleTypes = mealBoxType?.filter((m) => !m.isNoMeal);
      state.boxType = boxType;
      state.mealBoxTypes = scheduleTypes;
      state.showAddMore = false;
      state.selectedBoxType = [];
      state.selection =
        state.mealBoxTypes?.length > 0 ? state.mealBoxTypes[0] : {};
    },
    updateSelectedDate: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const ScheduleMealAction = scheduleMealSlice.actions;
export default scheduleMealSlice;
