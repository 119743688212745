import { FormControl, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const InputComponent = (params) => {
  const { control, controlName, hintText, error } = params;
  //   console.log('controlName', controlName);
  //   console.log('error', error);
  return (
    <FormControl variant='outlined' mb={2}>
      <Controller
        control={control}
        defaultValue=''
        name={controlName}
        render={({ field }) => (
          <TextField
            {...field}
            variant='outlined'
            placeholder={hintText}
            error={!!error}
            helperText={error?.message}
            {...params}
          />
        )}
      />
    </FormControl>
  );
};

export default InputComponent;
