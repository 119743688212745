import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AuthActions } from '../../../store/auth-slice';
import { axiosInstance } from '../AxiosInstance';
import { generateEmailOTP, getProfileData, validateEmailOTP } from './LoginApi';
import { navRoute } from '../../../utils/navInfo';

const schema = yup.object({
  otp: yup
    .string()
    .trim()
    .required('OTP is Required')
    .min(4, 'otp must be at least 4 characters'),
});

const TIMER_SECONDS = 60;

const VerifyOTPComponent = ({ email, goBack }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  let toastId;

  //   console.log('Form State: ');
  //   console.dir(errors);

  const [seconds, setSeconds] = useState(TIMER_SECONDS);
  const [resetTimer, setResetTimer] = useState(true);
  const counterRef = useRef();

  //Setting up countdown timer
  useEffect(() => {
    if (resetTimer) {
      const tick = () => {
        setSeconds((prev) => prev - 1);
        console.log('setInterval', seconds);
      };
      counterRef.current = setInterval(() => tick(), 1000);
    }
    return () => clearInterval(counterRef.current);
  }, [resetTimer]);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(counterRef.current);
      setResetTimer(false);
    }
  }, [seconds]);

  const handleErr = async (err) => {
    console.log('handleErr');
    toast.dismiss(toastId);
    try {
      toast.error(err.response.data.message);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const handleSuccessResponse = async (data) => {
    console.log('Success');
    toast.dismiss(toastId);
    console.log('Otp Response:');
    console.dir(data);
    // dispatch(AuthActions.saveAuthToken(data.data));
    const authToken = data.data.idToken;
    localStorage.setItem('token', authToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;

    getProfileInfo();
  };

  const { mutate: verifyOTP } = useMutation(
    ({ email, otp }) => validateEmailOTP(email, otp),
    {
      onSuccess: handleSuccessResponse,
      onError: handleErr,
    }
  );

  const { mutate: getProfileInfo } = useMutation(() => getProfileData(), {
    onSuccess: (data) => {
      console.log('Profile Data');
      console.dir(data);
      if (data.data.roles.includes('CanteenAdmin')) {
        toast.success('Login Successfully');
        localStorage.setItem('user', JSON.stringify(data.data));
        dispatch(
          AuthActions.saveAuthToken({
            token: localStorage.getItem('token'),
            user: JSON.parse(localStorage.getItem('user')),
          })
        );
        navigate(navRoute.navigateToFoodBox, {
          replace: true,
        });
      } else {
        dispatch(AuthActions.logout());
        localStorage.clear();
        toast.error(
          `The user must have an access to role of 'Canteen Admin' to access Menu Planner.`
        );
        goBack();
      }
    },
    onError: {},
  });

  const handleVerifyOtpSubmit = async (data) => {
    console.log('handleVerifyOtpSubmit');
    console.log(data.otp);
    toastId = toast.loading('Processing...');
    verifyOTP({ email, otp: data.otp });
  };

  const { mutate: generateOTP } = useMutation(
    (email) => generateEmailOTP(email),
    {
      onSuccess: () => {
        console.log('Success');
        toast.dismiss(toastId);
        toast.success('OTP Sent to the phone number.');
        setSeconds(TIMER_SECONDS);
        setResetTimer(true);
      },
      onError: (err) => {
        console.log('handleErr');
        toast.dismiss(toastId);
        try {
          toast.error(err.response.data.message);
        } catch (err) {
          toast.error('Something went wrong!');
        }
      },
    }
  );

  const handleResendOtpClick = () => {
    console.log('handleResendOtpClick');
    toastId = toast.loading('Processing...');
    generateOTP(email);
  };

  return (
    <form onSubmit={handleSubmit(handleVerifyOtpSubmit)}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='body2' gutterBottom mb={1} component='span'>
            Enter one time password (OTP)
          </Typography>
          {resetTimer ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: 2,
              }}
            >
              <Typography variant='s12_w400_grey'>Resend OTP:</Typography>
              <Typography variant='s12_w600_grey'>{seconds}</Typography>
            </Box>
          ) : (
            <Typography
              variant='s12_w400_text_clickable'
              onClick={handleResendOtpClick}
            >
              Resend OTP
            </Typography>
          )}
        </Box>
        <FormControl variant='outlined' fullWidth sx={{ mb: 1 }}>
          <Controller
            control={control}
            defaultValue=''
            name='otp'
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                placeholder='Enter OTP'
                type='number'
                error={!!errors.otp}
                helperText={errors.otp?.error}
              />
            )}
          />
        </FormControl>
        <Typography variant='s12_w500_grey'>
          We have shared OTP on E-mail
        </Typography>
      </Box>
      <Button
        variant='contained'
        fullWidth
        type='submit'
        sx={{
          mt: 3,
        }}
      >
        Continue
      </Button>
      <Button
        variant='text'
        fullWidth
        sx={{
          mt: 1,
        }}
        onClick={goBack}
      >
        Discard
      </Button>
    </form>
  );
};

export default VerifyOTPComponent;
