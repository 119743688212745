import React from 'react';
import './ExpMenu.css';
import lpd from './lpd.png';
import cloche from './cloche.png';
import { useNavigate } from 'react-router-dom';

export const ExpMenu = () => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate('/indiancuisine');
  };
  return (
    <div id='expmenu' className='expmenu'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <h2
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
            >
              Experience <span>Foodfe</span> Your Way
            </h2>
            <p
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              className='mt-4'
            >
              Whether you're looking for a convenient lunch solution or planning
              a grand event, we've got you covered.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-5 text-center offset-md-1 box'>
            <img
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              src={lpd}
              className='img-fluid mt-4'
              alt='logo'
            />
            <h4
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              className='mt-3'
            >
              Lunsj På Døra
            </h4>
            <p
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              className='mt-4'
            >
              Our corporate lunchbox service brings diverse, flavourful meals
              straight to your office. Choose from our daily offerings, place
              your order, and sit back as your lunchtime gets a global makeover.
              Step into the future of food!
            </p>
            {/* <button
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              className='btn btn-primary mt-2'
              onClick={handleClick}
            >
              Our Menu
            </button> */}
          </div>
          <div className='col-md-5 text-center box'>
            <img
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              src={cloche}
              className='img-fluid mt-4'
              alt='food closed'
            />
            <h4
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              className='mt-3'
            >
              Catering and Events
            </h4>
            <p
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              className='mt-4'
            >
              Hosting a gathering or planning a celebration? Let us bring the
              global feast to you. Click here to request custom orders for your
              event. We promise to make it memorable with our unique spread of
              world flavours.
            </p>
            {/* <button
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-easing='ease-in-out'
              className='btn btn-primary mt-2'
              onClick={handleClick}
            >
              Our Menu
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
