import React from 'react';
import classes from './TitleAndMenuList.module.css';
import { Box, Typography } from '@mui/material';
import MealItem from './MealItem';
import { layoutWidth } from '../../../utils/dimentions';
const TitleAndMenuList = ({ title, meals }) => {
  if (meals === undefined) return;
  return (
    <Box className={classes.container}>
      <Typography variant='h4'>{title}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexGrow: '0',
          flexDirection: 'row',
          maxWidth: layoutWidth,
          gap: 4,
          overflowX: 'auto',
        }}
      >
        {meals?.map((item) => {
          return <MealItem item={item} />;
        })}
      </Box>
    </Box>
  );
};

export default TitleAndMenuList;
