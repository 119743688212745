import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { useState } from 'react';
import { getBase64 } from '../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';
import toast from 'react-hot-toast';
const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}));
const uploadUrl = process.env.REACT_APP_FILE_UPLOAD_URL;

export default function UploadButtons() {
  const classes = useStyles();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileInfo, setFileInfo] = useState(null);
  const { externalId } = useSelector((state) => state.createMeal);

  const dispatch = useDispatch();
  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    const file = event.target.files[0];

    //File size should be less than 1 MB
    if (file?.size > 1 * 1024 * 1024) {
      toast.error('File size should be less than 1 MB');
      return;
    }

    if (
      file?.type?.includes('png') ||
      file?.type?.includes('jpg') ||
      file?.type?.includes('jpeg')
    )
      setFileInfo(file);
    else {
      toast.error('File type should be PNG or JPG');
    }
  };

  console.log('file', fileInfo);

  const handleFileUpload = async (event) => {
    let menuImageBase64 = '';
    await getBase64(fileInfo)
      .then((res) => {
        console.log(res);
        menuImageBase64 = res.substring(res.indexOf('base64,') + 7);
      })
      .catch((err) => console.log('base64 conversion error: ' + err));

    axios
      .post(
        uploadUrl,
        {
          AppName: 'foodfe',
          ExternalId: externalId,
          Content: menuImageBase64,
          NameWithExtension: fileInfo.name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      )
      .then((response) => {
        console.log(response);
        setUploadProgress(0);
        dispatch(CreateMealAction.updateMealUploadedImage(response.data));
      })
      .catch((error) => {
        console.error(error);
        setUploadProgress(0);
      });
  };

  return (
    <Stack sx={{ width: '50%' }}>
      <input
        accept='image/*'
        className={classes.input}
        id='contained-button-file'
        multiple
        type='file'
        onChange={onFileChange}
      />
      <label htmlFor='contained-button-file'>
        <Stack
          direction='row'
          display='flex'
          justifyContent='space-between'
          columnGap={2}
        >
          <Stack sx={{ width: '100%' }}>
            <Stack
              sx={{
                flexDirection: 'row',
                display: 'flex',
                border: '1px solid #BDBDBD',
                borderRadius: '5px',
                justifyContent: 'space-between',
                ':hover': {
                  cursor: 'pointer',
                },
                columnGap: 4,
                p: 2,
              }}
            >
              <Typography
                variant='s16_w400'
                component='span'
                sx={{ color: '#717074' }}
              >
                {fileInfo ? fileInfo.name : 'Click to browse files to upload'}
              </Typography>
              <Typography
                variant='s16_w400'
                component='span'
                sx={{ color: '#F5825E' }}
              >
                Browse
              </Typography>
            </Stack>
            {uploadProgress !== 0 ? (
              <LinearProgress variant='determinate' value={uploadProgress} />
            ) : null}
          </Stack>
          <Button onClick={handleFileUpload} variant='contained'>
            Upload
          </Button>
        </Stack>
      </label>
    </Stack>
  );
}
