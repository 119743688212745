import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OnBoardingForm from './OnBoardingForm';
import OnBoardingStoreInfo from './OnBoardingStoreInfo';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import TopBar from './TopBar';
import { Helmet } from 'react-helmet';
import { baseUrl } from '../../utils/utils';

const sxContainer = {
  width: '65vw',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  mt: 12,
  color: '#354465',
};

const OnBoardingPage = () => {
  const { t } = useTranslation();
  const [showStore, setShowStore] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const params = useSearchParams();

  const [searchParams] = useSearchParams();
  const rawSearchParam = decodeURIComponent(params[0]);

  //Get Key raw value from url
  // console.log(
  //   'encryptedKey',
  //   rawSearchParam.slice(rawSearchParam.indexOf('key=') + 4)
  // );

  // console.log('canteenId', searchParams.get('canteenId'));

  const submitForm = (formData) => {
    // console.log(`formData: ${formData}`);

    const finalFormData = {
      ...formData,
      canteenId: searchParams.get('canteenId'),
      encryptedString: rawSearchParam.slice(rawSearchParam.indexOf('key=') + 4),
    };

    const toastId = toast.loading(t('Sending your information...!'));
    axios
      .post(`${baseUrl}/v1/self-onboarding/users`, finalFormData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log(res);
        toast.success(t('Information sent successfully'));
        toast.dismiss(toastId);
        setMessage(t('You have been registered successfully'));
        setIsError(false);
        setShowStore(true);
      })
      .catch((err) => {
        toast.dismiss(toastId);
        if (err.response.status === 409) {
          setMessage(t('You have already been registered successfully'));
          setIsError(false);
          setShowStore(true);
        } else {
          console.log(err);
          setIsError(true);
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>FoodFe</title>
      </Helmet>
      <TopBar />
      <Box sx={sxContainer}>
        <img
          alt='Logo'
          src={process.env.PUBLIC_URL + '/assets/images/logo/logo.svg'}
          height='120px'
        />
        <Box
          mt={5}
          sx={{
            fontFamily: 'Playfair Display',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '32px',
          }}
        >
          {t('App Onboarding')}
        </Box>

        {!showStore ? (
          <OnBoardingForm
            submitForm={(formData) => submitForm(formData)}
            isError={isError}
          />
        ) : (
          <OnBoardingStoreInfo message={message} />
        )}
        {/* <OnBoardingForm  />
        <OnBoardingStoreInfo
          message={'You have already been registered successfully'}
        /> */}
      </Box>
    </>
  );
};

export default OnBoardingPage;
