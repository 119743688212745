import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ScheduleMealAction } from '../../../../store/schedule-meal-slice';
const DeleteIcon = process.env.PUBLIC_URL + '/assets/menu_planner/delete.svg';
const MealPlanItem = ({ mealType, mealBoxTypes }) => {
  const dispatch = useDispatch();
  return (
    <Grid2 container direction='row' alignItems='center' key={mealType.name}>
      <Grid2 xs={3}>
        <Typography variant='s16_w600' component='span'>
          Select a meal Plan:
        </Typography>
      </Grid2>
      <Grid2
        xs={4}
        sx={{
          border: '1px solid #BDBDBD',
          borderRadius: '5px',
          p: 2,
        }}
      >
        <Typography variant='s16_w400'>{mealType.name}</Typography>
      </Grid2>
      <Grid2 xs={1} ml={2}>
        <Box
          component='img'
          src={DeleteIcon}
          alt='Delete Icon'
          onClick={() =>
            dispatch(ScheduleMealAction.deleteSelectedMealBox(mealType))
          }
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default MealPlanItem;
