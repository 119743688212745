import { Stack, Typography } from '@mui/material';
import React from 'react';

const EnglishLabel = () => {
  return (
    <Stack direction='row' columnGap={1}>
      <Typography variant='s16_w500' component='span' alignItems='center'>
        English
      </Typography>
      <Typography
        variant='s10_w600_white'
        component='span'
        sx={{
          padding: '5px',
          borderRadius: '2px',
          backgroundColor: '#4F4F4F',
        }}
      >
        EN
      </Typography>
    </Stack>
  );
};

export default EnglishLabel;
