import React from 'react';
import './Together.css';
import together from './together.png';

export const Together = () => {
  return (
    <div className='together'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 d-flex align-items-center justify-content-center'>
            <div className='leftText'>
              <p
                data-aos='fade-up'
                data-aos-duration='800'
                data-aos-easing='ease-in-out'
              >
                By choosing Food Fe, you're joining us on a delicious journey
                towards a sustainable future.
              </p>
              <h2
                data-aos='fade-up'
                data-aos-duration='800'
                data-aos-easing='ease-in-out'
              >
                Together, let's make every meal matter!
              </h2>
              <p
                data-aos='fade-up'
                data-aos-duration='800'
                data-aos-easing='ease-in-out'
                className='mt-4'
              >
                Our platform empowers immigrants, giving them a stage to
                showcase their culinary skills and share their cultural
                heritage. We're also using food to tackle loneliness, organizing
                food festivals and events to help people connect and bond. With
                every meal you order from Food Fe, you're supporting this
                revolution. Bon appétit to that!
              </p>
            </div>
          </div>
          <div className='col-md-6'>
            <img
              data-aos='fade-left'
              data-aos-duration='1000'
              data-aos-delay='500'
              data-aos-easing='ease-in-out'
              src={together}
              className='img-fluid'
              alt='food'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
