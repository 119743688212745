import React, { useEffect, useRef, useState } from 'react';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { Box, Button, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarAction } from '../../../store/calander-slice';
import { parseNonNullablePickerDate } from '@mui/x-date-pickers/internals';
import dayjs from 'dayjs';
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const calendars = [
  {
    id: 'Veg',
    name: 'Veg',
    backgroundColor: '#46B52A33',
  },
  {
    id: 'Standard',
    name: 'Standard',
    backgroundColor: '#007AFF1A',
  },
  {
    id: 'Premium Lunsj',
    name: 'Premium Lunsj',
    backgroundColor: '#007AFF1A',
  },
  {
    id: 'Premium Middag',
    name: 'Premium Middag',
    backgroundColor: '#46B52A33',
  },
  {
    id: 'Halal',
    name: 'Halal',
    backgroundColor: '#F5825E33',
  },
  {
    id: 'NotApplicable',
    name: 'NotApplicable',
    backgroundColor: 'grey',
  },
];

const MainCalendar = ({
  data,
  previousMonthClick,
  nextMonthClick,
  todayClick,
  calMonthState,
}) => {
  const dispatch = useDispatch();
  const { filteredData, show7dayWeek, calendarView } = useSelector(
    (state) => state.calander
  );
  const [date, setDate] = useState('');
  const calendarRef = useRef();
  console.log('Calendar data');
  console.dir(data);
  useEffect(() => {
    const setCalanderData = () => {
      dispatch(CalendarAction.updateCalanderData(data));
      setDate(dayjs().add(calMonthState, 'month').format('MMMM YYYY'));
    };
    setCalanderData();
  }, [data, dispatch, calMonthState]);

  console.log('Main Calendar', filteredData);

  const handlePreviousMonthClick = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
    previousMonthClick();
  };

  const handleNextMonthClick = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
    nextMonthClick();
  };

  const handleTodayClick = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
    todayClick();
  };

  return (
    <Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        pt={3}
        pr={2}
        pl={3}
      >
        <Typography component='span' variant='s24_w600'>
          {date ? date : null}
        </Typography>
        <Stack direction='row' alignItems='center' columnGap={1}>
          <Button variant='contained' onClick={handleTodayClick}>
            Today
          </Button>
          <Stack
            direction='row'
            alignItems='center'
            columnGap={1}
            borderRight='1px solid #BDBDBD'
            pr={1}
            onClick={handlePreviousMonthClick}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <KeyboardArrowLeftIcon
              fontSize='large'
              sx={{
                border: '1px solid #BDBDBD',
                borderRadius: '5px',
              }}
            />
            <Typography>Previous Month</Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            columnGap={1}
            onClick={handleNextMonthClick}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Typography>Next Month</Typography>
            <KeyboardArrowRightIcon
              fontSize='large'
              sx={{
                border: '1px solid #BDBDBD',
                borderRadius: '5px',
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          border: '1px solid #BDBDBD',
          ml: 3,
          mt: 3,
        }}
      >
        <Calendar
          usageStatistics={false}
          view={calendarView}
          ref={calendarRef}
          isReadOnly={true}
          month={{
            workweek: !show7dayWeek,
          }}
          calendars={calendars}
          events={filteredData}
          template={{
            monthGridHeader: function (data) {
              var date = parseInt(data.date.split('-')[2], 10);
              //   console.log('data : ');
              //   console.dir(data);
              const month = new Date(data.date).getMonth();
              //   console.log('month : ' + months[month - 1]);
              return (
                '<span class="calendar-month-header" style="margin-left: 4px;">' +
                date +
                ' ' +
                months[month] +
                '</span>'
              );
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default MainCalendar;
