import { Stack, Typography } from '@mui/material';
import React from 'react';

const QuickAccessComponent = ({ handleQuickSectionClick }) => {
  return (
    <Stack
      direction='row'
      sx={{
        backgroundColor: '#FBFBFB',
        pt: 2,
        pb: 2,
        columnGap: 2,
      }}
    >
      <Typography variant='s16_w500'>Quick access:</Typography>
      <Typography
        variant='s16_w500_orange'
        sx={{
          borderRight: '1px solid #BDBDBD',
          pr: 2,
          ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }}
        onClick={() => handleQuickSectionClick('food_box_details')}
      >
        Food Box Details
      </Typography>
      <Typography
        variant='s16_w500_orange'
        sx={{
          borderRight: '1px solid #BDBDBD',
          pr: 2,
          ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }}
        onClick={() => handleQuickSectionClick('ingredients')}
      >
        Food Box Ingredients
      </Typography>
      <Typography
        variant='s16_w500_orange'
        sx={{
          borderRight: '1px solid #BDBDBD',
          pr: 2,
          ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }}
        onClick={() => handleQuickSectionClick('allergens')}
      >
        Allergens
      </Typography>
      <Typography
        variant='s16_w500_orange'
        sx={{
          ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }}
        onClick={() => handleQuickSectionClick('trevia')}
      >
        Trivia & Images/Videos
      </Typography>
    </Stack>
  );
};

export default QuickAccessComponent;
