import { createSlice } from '@reduxjs/toolkit';
import { gl } from 'date-fns/locale';

const initialState = {
  language: localStorage.getItem('language') ?? 'English',
};

const globalUiSlice = createSlice({
  initialState,
  name: 'global-ui-slice',
  reducers: {
    updateSelectedLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },
  },
});

export const GlobalUIAction = globalUiSlice.actions;
export default globalUiSlice;
