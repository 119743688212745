import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { MealAction } from '../../../../store/meal-slice';
const SummarySearchComponent = () => {
  const { showOnly, searchValue } = useSelector((state) => state.meals);
  const { language } = useSelector((state) => state.globalUi);
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    dispatch(
      MealAction.searchMealList({ searchName: e.target.value, language })
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: '0 2.4rem',
        alignItems: 'center',
        columnGap: 4,
        backgroundColor: '#FBFBFB',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
        }}
      >
        <Typography component='span' variant='s16_w500'>
          Quick search:
        </Typography>
        <FormControl
          sx={{
            minWidth: '40rem',
          }}
        >
          <TextField
            placeholder='Enter a keyword to search boxes'
            value={searchValue}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
        }}
      >
        <Typography component='span' variant='s16_w500'>
          Show only:
        </Typography>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row', columnGap: 3 }}>
          {['Vegetarian', 'Halal'].map((item) => (
            <FormControlLabel
              key={item}
              checked={showOnly?.includes(item) ?? false}
              onChange={() =>
                dispatch(
                  MealAction.updateShowOnlyFilter({
                    filterType: item,
                    language,
                  })
                )
              }
              control={<Checkbox />}
              label={item}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default SummarySearchComponent;
