import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const FoodBoxTable = () => {
  const { mealList } = useSelector((state) => state.meals);
  const { language } = useSelector((state) => state.globalUi);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleOnClickMealBox = (item) => {
    navigate('edit-food-box', {
      state: { item },
    });
  };

  useEffect(() => {
    (function () {
      setData([...mealList]);
    })();
  }, [language, mealList]);

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        size: 150,
        accessorFn: (row) =>
          language === 'English' ? row.details[0].name : row.details[1].name,
      },
      {
        header: 'Description',
        size: 200,
        accessorFn: (row) =>
          language === 'English'
            ? row.details[0].description
            : row.details[1].description,
      },
      {
        header: 'Products',
        size: 150,
        accessorFn: (row) =>
          language === 'English'
            ? row.details[0].contents
            : row.details[1].contents,
      },
      {
        header: 'Tags',
        size: 150,
        accessorFn: (row) =>
          language === 'English' ? row.details[0].tags : row.details[1].tags,
      },
      {
        header: 'Allergies',
        size: 150,
        accessorFn: (row) =>
          row.allergies
            .map((item) => (language === 'English' ? item.nameEn : item.nameNo))
            .join(','),
      },
      {
        accessorKey: 'meatType',
        header: 'Meat Type',
        size: 100,
      },
      {
        accessorKey: 'isHalal',
        header: 'Halal',
        size: 50,
        accessorFn: (row) => (row.isHalal ? 'true' : 'false'),
      },
    ],
    [language]
  );
  //column definitions...

  const table = useMaterialReactTable({
    columns,
    data: data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    enableFullScreenToggle: true,
    enableRowActions: true,
    getRowId: (row) => row.id,
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {},
    },
    muiTableBodyCellProps: {
      sx: {
        fontWeight: 'normal',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    muiTopToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <IconButton onClick={() => handleOnClickMealBox(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip> */}
      </Box>
    ),
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return <MaterialReactTable table={table} />;
};

export default FoodBoxTable;
