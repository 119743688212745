import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoginEmailComponent from './LoginEmailComponent';
import classes from './MealPlannerLogin.module.css';
import VerifyOTPComponent from './VerifyOTPComponent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { navRoute } from '../../../utils/navInfo';

const LoginUIState = Object.freeze({
  EmailScreen: 1,
  OTPScreen: 2,
});
const MealPlannerLogin = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [uiState, setUiState] = useState(LoginUIState.EmailScreen);
  const [email, setEmail] = useState('');

  useEffect(() => {
    (function () {
      console.log('token', token);
      if (token) {
        navigate(navRoute.navigateToFoodBox, { replace: true });
      }
    })();
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.containerCenter}>
        {uiState === LoginUIState.EmailScreen ? (
          <LoginEmailComponent
            moveToOTPScreen={(emailId) => {
              setEmail(emailId);
              setUiState(LoginUIState.OTPScreen);
            }}
          />
        ) : (
          <VerifyOTPComponent
            email={email}
            goBack={() => setUiState(LoginUIState.EmailScreen)}
          />
        )}
        ̥
      </Box>
      {/* <PlannerFooter /> */}
    </Box>
  );
};

export default MealPlannerLogin;
