import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleSummaryAction } from '../../../store/schedule-summary-slice';
const ScheduleSearchComponent = () => {
  const dispatch = useDispatch();
  const { searchValue, showOnly } = useSelector(
    (state) => state.scheduleSummary
  );

  const handleSearch = (e) => {
    dispatch(ScheduleSummaryAction.searchScheduleList(e.target.value));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: '0 2.4rem',
        alignItems: 'center',
        columnGap: 4,
        backgroundColor: '#FBFBFB',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
        }}
      >
        <Typography component='span' variant='s16_w500'>
          Quick search:
        </Typography>
        <TextField
          sx={{
            minWidth: '40rem',
          }}
          value={searchValue}
          onChange={handleSearch}
          placeholder='Enter a keyword to search boxes'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
        }}
      >
        <Typography component='span' variant='s16_w500'>
          Show only:
        </Typography>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row', columnGap: 3 }}>
          {['Vegetarian', 'Halal'].map((item) => (
            <FormControlLabel
              checked={showOnly?.includes(item) ?? false}
              onChange={(e) =>
                dispatch(ScheduleSummaryAction.updateShowOnlyFilter(item))
              }
              control={<Checkbox />}
              label={item}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default ScheduleSearchComponent;
