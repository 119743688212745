import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import InputComponent from '../../../../../Components/menu_planner/InputComponent';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';

const ServingSizeComponent = ({ control, errors }) => {
  const dispatch = useDispatch();
  const sizeUnit = useSelector((state) => state.createMeal.sizeUnit);
  return (
    <Grid2 container columnSpacing={1}>
      <Grid2 xs={2}>
        <Typography variant='s16_w600' component='span'>
          Specify Serving Size:
        </Typography>
      </Grid2>
      <Grid2 xs={10} direction='row' gap={3} alignItems='center' display='flex'>
        <InputComponent
          control={control}
          controlName='serving_size'
          hintText='0'
          error={errors?.serving_size}
          type='number'
        />
        {/* <FormControl>
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='grams'
            name='radio-buttons-group'
            row
            value={sizeUnit}
            onChange={(e) => {
              dispatch(
                CreateMealAction.updateServingSizeUnitType(e.target.value)
              );
            }}
          >
            <FormControlLabel value='grams' control={<Radio />} label='Grams' />
            <FormControlLabel
              value='ounces'
              control={<Radio />}
              label='Ounces'
            />
          </RadioGroup>
        </FormControl> */}
      </Grid2>
    </Grid2>
  );
};

export default ServingSizeComponent;
