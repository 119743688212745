import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import InputComponent from '../../../../../Components/menu_planner/InputComponent';
import CreateFoodBoxScreen from '../CreateFoodBoxScreen';
import { CreateMealAction } from '../../../../../store/create-meal-slice';

const schema = yup.object({
  english: yup.string().required('Info is required'),
  nor: yup.string().required('Info is required'),
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const AddNewTempFoodIngredientItemDialog = ({
  variant = 'contained',
  type = 'primary',
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (data) => {
    console.log('submitted', data);
    if (type === 'primary') {
      dispatch(CreateMealAction.addNewIngredient({ type: 'primary', data }));
    } else {
      dispatch(CreateMealAction.addNewIngredient({ type: 'additional', data }));
    }
    reset();
    setOpen(false);
  };

  return (
    <Fragment>
      <Button variant={variant} onClick={handleClickOpen}>
        Add new Item
      </Button>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(handleSave)(e);
            }}
          >
            <Stack>
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography variant='s20_w500' component='span'>
                  <strong>Food Box Ingredients:</strong> Add a new item
                </Typography>
                <CloseIcon
                  sx={{
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={handleClose}
                />
              </Stack>
              <hr />
              <Stack
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                pt={10}
                pb={10}
                rowGap={3}
              >
                <Stack direction='row' gap={2}>
                  <Stack gap={1}>
                    <Stack direction='row' gap={1}>
                      <Typography
                        variant='s10_w600_white'
                        component='span'
                        sx={{
                          padding: '5px',
                          borderRadius: '2px',
                          backgroundColor: '#4F4F4F',
                        }}
                      >
                        EN
                      </Typography>
                      <Typography variant='s16_w600' component='span'>
                        Add Item
                      </Typography>
                    </Stack>
                    <InputComponent
                      control={control}
                      controlName='english'
                      hintText='Ingredient Name'
                      error={errors.english}
                    />
                  </Stack>
                  <Stack gap={1}>
                    <Stack direction='row' gap={1}>
                      <Typography
                        variant='s10_w600_white'
                        component='span'
                        sx={{
                          padding: '5px',
                          borderRadius: '2px',
                          backgroundColor: '#4F4F4F',
                        }}
                      >
                        NW
                      </Typography>
                      <Typography variant='s16_w600' component='span'>
                        Add Item
                      </Typography>
                    </Stack>
                    <InputComponent
                      control={control}
                      controlName='nor'
                      hintText='Ingredient Name'
                      error={errors.nor}
                    />
                  </Stack>
                </Stack>
                <Stack direction='row' gap={2}>
                  <Button variant='contained' type='submit'>
                    Save
                  </Button>
                  <Button variant='outlined' onClick={handleClose}>
                    Discard
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AddNewTempFoodIngredientItemDialog;
