import { ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { router } from './utils/RouteProvider';
import { theme } from './utils/ThemeProvider';

function App() {
  // Create a client
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <Toaster
        position='top-right'
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: 'rgba(53, 68, 99, 1)',
            color: '#fff',
            fontWeight: 'bold',
          },
        }}
      />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {/* <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/indiancuisine' element={<MenuScreen />} />
            <Route path='/cart' element={<CartScreen />} />
            <Route path='/order-placed' element={<OrderPlacedScreen />}
             />
            <Route path='/menu-planner' element={<PlannerHeader />} />
          </Routes>
        </BrowserRouter> */}
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
