import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { generateEmailOTP } from './LoginApi';
const schema = yup.object({
  email: yup.string().trim().required('Email address is Required').email(),
  // password: yup
  //   .string()
  //   .trim()
  //   .required('Password is Required')
  //   .min(5, 'Password must be at least 5 characters'),
});
const LoginEmailComponent = ({ moveToOTPScreen }) => {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  let toastId;

  const handleErr = async (err) => {
    console.log('handleErr');
    toast.dismiss(toastId);

    try {
      toast.error(err.response.data.message);
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const handleSuccessResponse = async () => {
    console.log('Success');
    toast.dismiss(toastId);
    toast.success('OTP Sent to the your email id.');
    const email = getValues('email');
    moveToOTPScreen(email);
  };

  const { mutate: generateOTP } = useMutation(
    (email) => generateEmailOTP(email),
    {
      onSuccess: handleSuccessResponse,
      onError: handleErr,
    }
  );

  const handelLoginClick = async (data) => {
    console.log('handelLoginClick');
    console.dir(data);
    toastId = toast.loading('Processing...');
    generateOTP(data.email);
  };

  return (
    <form onSubmit={handleSubmit(handelLoginClick)}>
      <Box>
        <Typography variant='body2' gutterBottom mb={1}>
          Email ID
        </Typography>
        <FormControl variant='outlined' mb={1} fullWidth>
          <Controller
            control={control}
            defaultValue=''
            name='email'
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                placeholder='Enter your email address'
                type='text'
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </FormControl>
      </Box>
      <Button
        variant='contained'
        fullWidth
        type='submit'
        sx={{
          mt: 2,
        }}
      >
        Log In
      </Button>
      <Typography
        mt={3}
        sx={{
          textAlign: 'center',
        }}
      >
        By submitting you accept out{' '}
        <a
          target='_blank'
          style={{
            color: '#1679ef',
          }}
          href='https://lunchatdoor.prod.dcx.no/mobile-assets/terms&condition_en.html'
        >
          Terms and conditions
        </a>{' '}
        and{' '}
        <a
          target='_blank'
          style={{
            color: '#1679ef',
          }}
          href='https://lunchatdoor.prod.dcx.no/mobile-assets/terms&condition_en.html'
        >
          privacy policy
        </a>
      </Typography>
    </form>
  );
};

export default LoginEmailComponent;
