import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kitchen: '',
  showAll: true,
  standard: true,
  veg: true,
  halal: true,
  'Premium Lunsj': true,
  'Premium Middag': true,
  calanderData: [],
  filteredData: [],
  show7dayWeek: false,
  calendarView: 'month',
};

const getFilteredData = (state) => {
  if (
    state.kitchen === 'Bergen'
      ? state['Premium Lunsj'] && state['Premium Middag']
      : state.standard && state.veg && state.halal
  ) {
    state.showAll = true;
  }
  return state.calanderData.filter((meal) => {
    if (state.kitchen === 'Bergen') {
      if (state['Premium Lunsj'] && meal.calendarId === 'Premium Lunsj')
        return true;
      if (state['Premium Middag'] && meal.calendarId === 'Premium Middag')
        return true;
    } else {
      if (state.standard && meal.calendarId === 'Standard') return true;
      if (state.veg && meal.calendarId === 'Veg') return true;
      if (state.halal && meal.calendarId === 'Halal') return true;
    }
    return false;
  });
};

const createCalendarSlice = createSlice({
  initialState,
  name: 'calander-slice',
  reducers: {
    updateShowAllCheckbox: (state, action) => {
      state.showAll = !state.showAll;
      if (state.showAll === true) {
        state.standard = true;
        state.veg = true;
        state.halal = true;
        state['Premium Lunsj'] = true;
        state['Premium Middag'] = true;
        state.filteredData = state.calanderData;
      } else {
        state.standard = false;
        state.veg = false;
        state.halal = false;
        state['Premium Lunsj'] = false;
        state['Premium Middag'] = false;
        state.filteredData = [];
      }
    },
    updateStandardCheckbox: (state, action) => {
      state.showAll = false;
      state.standard = !state.standard;
      state.filteredData = getFilteredData(state);
    },
    updateVegCheckbox: (state, action) => {
      state.showAll = false;
      state.veg = !state.veg;
      state.filteredData = getFilteredData(state);
    },
    updateHalalCheckbox: (state, action) => {
      state.showAll = false;
      state.halal = !state.halal;
      state.filteredData = getFilteredData(state);
    },
    updatePremiumLunsjCheckbox: (state, action) => {
      state.showAll = false;
      state['Premium Lunsj'] = !state['Premium Lunsj'];
      state.filteredData = getFilteredData(state);
    },
    updatePremiumMiddagCheckbox: (state, action) => {
      state.showAll = false;
      state['Premium Middag'] = !state['Premium Middag'];
      state.filteredData = getFilteredData(state);
    },
    updateCalanderData: (state, action) => {
      state.calanderData = action.payload;
      if (state.showAll === true) {
        state.filteredData = [...state.calanderData];
      } else {
        state.filteredData = state.calanderData.filter((meal) => {
          return state.standard
            ? meal.calendarId === 'Standard'
            : false || state.veg
            ? meal.calendarId === 'Veg'
            : false || state.halal
            ? meal.calendarId === 'Halal'
            : false || state['Premium Lunsj']
            ? meal.calendarId === 'Premium Lunsj'
            : false || state['Premium Middag']
            ? meal.calendarId === 'Premium Middag'
            : false;
        });
      }
    },
    updateShow7DayWeek: (state, action) => {
      state.show7dayWeek = !state.show7dayWeek;
    },
    updateCalendarView: (state, action) => {
      state.calendarView = action.payload;
    },
    updateKitchen: (state, action) => {
      state.kitchen = action.payload;
    },
  },
  reset: () => initialState,
});

export const CalendarAction = createCalendarSlice.actions;
export default createCalendarSlice;
