import React from 'react';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip } from '@mui/material';
import dayjs from 'dayjs';

const EditIcon = process.env.PUBLIC_URL + '/assets/menu_planner/edit_icon.svg';

const ScheduleTable = () => {
  const navigate = useNavigate();
  const { filteredScheduleList, scheduleType } = useSelector(
    (state) => state.scheduleSummary
  );

  const handleScheduleClick = (schedule) => {
    console.log(schedule);
    navigate('create-schedule', {
      state: { date: schedule.date, boxType: scheduleType },
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Schedule Date',
        size: 150,
        sortingFn: (rowA, rowB, columnId) => {
          const dateA = Date.parse(rowA.original.date);
          const dateB = Date.parse(rowB.original.date);

          // console.log('Date values', rowA.original.date, rowB.original.date);
          // console.dir(rowA);
          // console.log('orting date: ', dateA, dateB);
          // console.log('Date values: ', rowA.original.date, dateA - dateB);
          return dateA - dateB;
        },
      },
      {
        accessorKey: 'mealPlan.name', //access nested data with dot notation
        header: 'Meal Plan',
        size: 150,
        enableSorting: false,
      },

      {
        accessorKey: 'name', //normal accessorKey
        header: 'Meal Box name',
        size: 200,
        enableSorting: false, //disable sorting for all columns
      },
    ],
    []
  );
  //column definitions...

  const table = useMaterialReactTable({
    columns,
    data: filteredScheduleList, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    enableFullScreenToggle: true,
    enableRowActions: true,
    positionActionsColumn: 4,
    initialState: {
      sorting: [
        {
          id: 'date', //sort by age by default on page load
          desc: false,
        },
      ],
    },
    // muiTableBodyProps: {
    //   sx: {
    //     //stripe the rows, make odd rows a darker color
    //     '& tr:nth-of-type(odd) > td': {
    //       backgroundColor: '#f5f5f5',
    //     },
    //   },
    // },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    rowStyle: {},
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 160, //default size is usually 180
    },
    muiTableBodyRowProps: ({ row }) => {
      return {
        sx: {
          backgroundColor: 'white',
        },
      };
    },
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {},
    },
    muiTableBodyCellProps: {
      sx: {
        fontWeight: 'normal',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    muiTopToolbarProps: {
      sx: {
        minHeight: '5rem',
      },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <IconButton onClick={() => handleScheduleClick(row.original)}>
            <img src={EditIcon} alt='Edit' />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip> */}
      </Box>
    ),
  });

  //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
  return <MaterialReactTable table={table} />;
};

export default ScheduleTable;
