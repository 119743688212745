import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { menuItems } from '../../../utils/menuUtils';
import PhoneItemListScreen from './PhoneItemListScreen';
import PhoneMenuScreen from './PhoneMenuScreen';

const PhoneMenuCategoryScreen = () => {
  const [uiState, setUiState] = useState(0);
  const [title, setTitle] = useState(menuItems[0].title);

  const navigateToPhoneItemListScreen = (title) => {
    setTitle(title);
    setUiState(1);
  };

  const handleGoBack = () => {
    setUiState(0);
  };

  let screen;

  if (uiState === 0) {
    screen = (
      <PhoneMenuScreen handleOnItemClick={navigateToPhoneItemListScreen} />
    );
  } else {
    screen = <PhoneItemListScreen title={title} goBack={handleGoBack} />;
  }
  return screen;
};

export default PhoneMenuCategoryScreen;
