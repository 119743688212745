import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isViewAsTable: false,
}

const boxSummarySlice = createSlice({
    initialState,
    name: 'box-summary-slice',
    reducers: {
        reset: () => initialState,
        toggleViewAsState: (state, action) => {
            state.isViewAsTable = !state.isViewAsTable
        } 
    }
})

export const BoxSummaryAction = boxSummarySlice.actions;
export default boxSummarySlice