import {
  Button,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { forwardRef, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import EnglishLabel from '../../../components/EnglishLabel';
import NorwegianLabel from '../../../components/NorwegianLabel';
import ImagePreviewComponent from './ImagePreviewComponent';
import InputComponent from '../../../../../Components/menu_planner/InputComponent';
import UploadButtons from './UploadButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';
const DocTick = process.env.PUBLIC_URL + '/assets/menu_planner/doc_tick.png';
const GoOut = process.env.PUBLIC_URL + '/assets/menu_planner/go_out.svg';
const TriviaImageVideoSection = forwardRef(({ control, errors }, ref) => {
  const { mealImage } = useSelector((state) => state.createMeal);
  const dispatch = useDispatch();
  return (
    <Stack
      ref={ref}
      sx={{
        rowGap: 3,
        p: 3,
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
      }}
    >
      <Stack display='flex' direction='row' alignItems='center'>
        <Typography variant='s20_w600' component='span' mr={3}>
          Image{/* Trivia & Images/Videos */}
        </Typography>
        <Typography variant='s16_w500' component='span' mr={1}>
          (Optional section, but <strong>recommended</strong> to make this food
          box interesting for our customers)
        </Typography>
        <CheckCircleOutlineIcon sx={{ color: '#46B52A' }} />
      </Stack>
      <hr />
      {/* <Grid2 container columnSpacing={1}>
        <Grid2 xs={2}>
          <Typography variant='s16_w500'>
            Add an interesting <strong>Trivia:</strong>
          </Typography>
        </Grid2>
        <Grid2 xs={5} display='flex' flexDirection='column' rowGap={1}>
          <Stack rowGap={1}>
            <EnglishLabel />
            <InputComponent
              control={control}
              controlName='trevia'
              hintText='Add an interesting trivia about this food box'
              error={errors?.trevia}
              rows={3}
              multiline
              inputProps={{
                maxLength: 200,
              }}
            />
          </Stack>
        </Grid2>
        <Grid2 xs={5} display='flex' flexDirection='column' rowGap={1}>
          <Stack rowGap={1}>
            <NorwegianLabel />
            <InputComponent
              control={control}
              controlName='trevia_nw'
              hintText='Legg til en intessant trivia om denne matboksen'
              error={errors?.trevia_nw}
              rows={3}
              multiline
              inputProps={{
                maxLength: 200,
              }}
            />
          </Stack>
        </Grid2>
      </Grid2> */}
      <Grid2 container columnSpacing={1}>
        <Grid2 xs={2}>
          <Typography variant='s16_w500'>
            Add <strong>Images:*{/* /Videos:* */}</strong>
          </Typography>
        </Grid2>
        <Grid2
          xs={10}
          sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
        >
          <Typography variant='s16_w500'>
            Adding images to the food box provides a glimpse of the food to the
            end-users. Please follow our&nbsp;
            {
              <Typography
                component='span'
                sx={{
                  color: '#F5825E',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                guidelines
              </Typography>
            }
            , to add images.
          </Typography>
          <Stack direction='row' gap={1}>
            <img
              src={DocTick}
              style={{ width: '2.4rem', height: '2.4rem' }}
              alt='Doc Tick'
            />
            <Typography
              variant='s14_w600'
              component='span'
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
                color: '#F5825E',
                textDecoration: 'underline',
              }}
            >
              View Food Image Guidelines
            </Typography>
            <img
              src={GoOut}
              style={{ width: '2.4rem', height: '2.4rem' }}
              alt='Doc Tick'
            />
          </Stack>
          {/* <Stack
            sx={{
              flexDirection: 'row',
              display: 'flex',
              border: '1px solid #BDBDBD',
              borderRadius: '5px',
              justifyContent: 'space-between',
              width: '60%',
              ':hover': {
                cursor: 'pointer',
              },
              p: 2,
            }}
          >
            <Typography
              variant='s16_w400'
              component='span'
              sx={{ color: '#717074' }}
            >
              Click to browse files to upload
            </Typography>
            <Typography
              variant='s16_w400'
              component='span'
              sx={{ color: '#F5825E' }}
            >
              Browse
            </Typography>
          </Stack> */}
          {/* <TextField type='file' sx={{ width: '60%' }} accept='image/*' /> */}
          {mealImage === null ? (
            <>
              <UploadButtons />
              <Typography variant='s14_w500'>
                File formats accepted:&nbsp;
                <strong>.jpg, .png</strong> | Maximum size:&nbsp;
                <strong>1MB file</strong>
              </Typography>
            </>
          ) : (
            <Stack
              border='1px solid #BDBDBD'
              p={3}
              gap={3}
              width='30%'
              borderRadius='5px'
            >
              <img
                src={mealImage?.thumbnailUrl ?? 'image'}
                alt={mealImage?.thumbnailUrl ?? 'image'}
              />
              <Button
                variant='outlined'
                onClick={() =>
                  dispatch(CreateMealAction.deleteMealUploadedImage())
                }
              >
                Cancel
              </Button>
            </Stack>
          )}
          {/* <Typography variant='s14_w500'>
            Maximum <strong>4 images/videos</strong> | File formats
            accepted:&nbsp;
            <strong>.jpg, .png, .mp4, .mov, .avi</strong> | Maximum size:&nbsp;
            <strong>1MB of each file</strong>
          </Typography> */}
          {/* <Stack gap={1}>
            <Typography variant='s16_w500'>
              Preview and choose images/vidoes to upload for this food box:
            </Typography>
            <Typography variant='s16_w500'>
              <Typography sx={{ color: '#FF2D55' }} component='span'>
                Note:
              </Typography>{' '}
              Maximum 4 uploaded images will be uploaded and visible to the
              customers using the FoodFe app.
            </Typography>
          </Stack>
          <Stack display='flex' flexDirection='row' gap={3} flexWrap='wrap'>
            {[1, 2, 3, 4, 5].map((item) => (
              <ImagePreviewComponent
                title={item}
                url={`https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2`}
              />
            ))}
          </Stack> */}
        </Grid2>
      </Grid2>
    </Stack>
  );
});

export default TriviaImageVideoSection;
