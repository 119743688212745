import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoaderProgressComponent = () => {
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '10%',
        marginBottom: '10%',
        textAlign: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoaderProgressComponent;
