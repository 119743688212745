import { Box, Button, Dialog, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CloseButtonComponent from '../../../Components/CloseButtonComponent';
import { CartActions } from '../../../store/cart-slice';

function DialogRemoveAllItemFromCart({ onClose, open, item }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };

  const handleOkClick = () => {
    dispatch(CartActions.removeAllItemsFromCart(item.id));
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Stack
        sx={{
          padding: 4,
          fontFamily: 'Rubik',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '19px',
          color: '#354463',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{item.name}</span>
          <CloseButtonComponent onClose={handleClose} />
        </Box>
        <Box
          mt={4}
          sx={{
            fontSize: '14px',
          }}
        >
          {t('Delete all meals from the cart?', { meals: item.name })}
        </Box>
        <Box
          mt={4}
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'end',
          }}
        >
          <Button
            variant='rounded'
            color='primary'
            onClick={() => handleOkClick()}
          >
            {t('Remove All')}
          </Button>
          {/* <Button
            variant='rounded'
            color='primary'
            onClick={() => handleClose()}
          >
            {t('Cancel')}
          </Button> */}
        </Box>
      </Stack>
    </Dialog>
  );
}

DialogRemoveAllItemFromCart.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DialogRemoveAllItemFromCart;
