import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';
import { MealAction } from '../../../../../store/meal-slice';

const AllergiesSection = forwardRef(function (porps, ref) {
  const dispatch = useDispatch();
  const { allergies: selectedAllergies, hasAlternativePreprationForRecipe } =
    useSelector((state) => state.createMeal);
  const { filteredAllergiesList } = useSelector((state) => state.meals);
  const { language } = useSelector((state) => state.globalUi);
  const [search, setSearch] = useState('');

  const handleAlternativePreprationChange = (event) => {
    dispatch(
      CreateMealAction.updateHasAlternativeServingSelection(event.target.value)
    );
  };

  const handleSearchAllergy = (event) => {
    setSearch(event.target.value);
    dispatch(
      MealAction.searchAllergiesList({
        searchName: event.target.value,
        language,
      })
    );
  };

  return (
    <Stack
      ref={ref}
      sx={{
        rowGap: 3,
        p: 3,
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
      }}
    >
      <Typography variant='s20_w600'>Allergies</Typography>
      <hr />
      <Stack direction='row' display='flex' alignItems='center' columnGap={2}>
        <Typography component='span' variant='s16_w500'>
          Select from options below or quick search for related Allergies: *
        </Typography>
        <FormControl variant='outlined' sx={{ width: '40%' }}>
          <TextField
            variant='outlined'
            placeholder='Search for allergies'
            value={search}
            onChange={handleSearchAllergy}
          />
        </FormControl>
      </Stack>
      <Stack overflow='auto' mt={2}>
        <FormGroup row>
          {filteredAllergiesList?.map((allergy) => {
            const item = selectedAllergies?.find(
              (item) => item.id === allergy.id
            );

            return (
              <FormControl sx={{ width: '20%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item === undefined ? false : true}
                      onChange={() =>
                        dispatch(CreateMealAction.updateAllergiesState(allergy))
                      }
                    />
                  }
                  label={
                    language === 'English' ? allergy.nameEn : allergy.nameNo
                  }
                />
              </FormControl>
            );
          })}
        </FormGroup>
      </Stack>
      {/* <Typography variant='s16_w500'>
        <strong>Any alternative preparation of this recipe</strong> for the
        customers with allergies specified above?*
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={hasAlternativePreprationForRecipe}
          onChange={handleAlternativePreprationChange}
          row
        >
          <FormControlLabel
            value='yes'
            control={<Radio />}
            label={
              <Typography variant='s16_w500'>
                <strong>Yes.</strong> The kitchen will provide any alternative
                preparation.
              </Typography>
            }
          />
          <FormControlLabel
            value='no'
            control={<Radio />}
            label={
              <Typography variant='s16_w500'>
                <strong>No.</strong> The kitchen will not provide any
                alternative preparation.
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl> */}
    </Stack>
  );
});

export default AllergiesSection;
