import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  mealMap: {},
};

const indianCuisineMealSlice = createSlice({
  name: 'meal',
  initialState,
  reducers: {
    addItemsToMenu: (state, action) => {
      state.mealMap = action.payload;
    },
  },
});

export const IndianCuisineMealAction = indianCuisineMealSlice.actions;
export default indianCuisineMealSlice;
