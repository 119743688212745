import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as React from 'react';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment/locale/nb';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  dateFormatForServer,
  dateFormatToDisplay,
  disableWeekEndDays,
  nextBusinessDateFromCurrentDate,
  oneDayFromCurrentDate,
  twoDaysFromCurrentDate,
} from '../utils/dateUtils';

const DatePickerComponent = ({
  onValidDateSelection,
  isValidDate,
  sxTextFieldHalf = {
    width: '24.5%',
    minWidth: '24.5%',
  },
  startDate = new Date(),
}) => {
  const [value, setValue] = useState(startDate);

  const { t, i18n } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(t('Invalid Date'));
  const today = dayjs(new Date());

  useEffect(() => {
    const onDateChange = () => {
      if (isError) {
        // console.log('onDateSelection if', !isError);
        try {
          const selectedDate = dayjs(value?.$d);
          if (selectedDate?.isBefore(today)) {
            setError(t('Please select future Date'));
          } else setError(t('Invalid Date'));
        } catch (e) {
          setError(t('Invalid Date'));
        }
      } else {
        // console.log('onDateSelection ', !isError);
        setError(null);
        onValidDateSelection(value?.$d);
      }
    };
    onDateChange();
  }, [isError, onValidDateSelection, t, today, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
      <DatePicker
        value={value}
        disablePast
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          isValidDate(isError);
          setIsError(params.error);
          return (
            <TextField
              variant='outlined'
              {...params}
              error={params.error}
              helperText={params.error ? error : ''}
              sx={sxTextFieldHalf}
              placeholder='Select'
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;

export const DatePickerSelectComponent = ({ selectedDate, onDateSelected }) => {
  const { i18n } = useTranslation();

  const handleChange = (newValue) => {
    // console.log('handleChange', newValue.format(dateFormatToDisplay));
    onDateSelected(newValue.format(dateFormatForServer));
  };

  const [language, setLanguage] = useState(
    i18n.resolvedLanguage === 'en' ? 'en' : 'nb'
  );

  useEffect(() => {
    setLanguage(i18n.resolvedLanguage === 'en' ? 'en' : 'nb');
    if (i18n.resolvedLanguage === 'en') moment.locale('en_GB');
    else moment.locale('nb_NO');
  }, [i18n.resolvedLanguage]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      <DatePicker
        inputFormat={dateFormatToDisplay}
        value={selectedDate}
        onChange={handleChange}
        disableMaskedInput
        renderInput={(params) => (
          <TextField
            {...params}
            disabled
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
                cursor: 'default',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const DatePickerTwoDaysFutureComponent = ({
  isDisabled = false,
  selectedDate,
  onDateSelected,
  sxTextFieldHalf = {
    width: '24.5%',
    minWidth: '24.5%',
    backgroundColor: '#fff',
  },
}) => {
  const handleChange = (newValue) => {
    console.log('handleChange', newValue.format(dateFormatToDisplay));
    onDateSelected(newValue.format(dateFormatForServer));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en'}>
      <DatePicker
        inputFormat={dateFormatToDisplay}
        value={selectedDate}
        onChange={handleChange}
        disabled={isDisabled}
        disablePast
        disableMaskedInput
        shouldDisableDate={twoDaysFromCurrentDate}
        maxDate={moment().add(2, 'month')}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ...sxTextFieldHalf,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
                cursor: 'default',
              },
            }}
            disabled
          />
        )}
      />
    </LocalizationProvider>
  );
};
export const DatePickerOneDaysFutureComponent = ({
  isDisabled = false,
  selectedDate,
  onDateSelected,
  sxTextFieldHalf = {
    width: '24.5%',
    minWidth: '24.5%',
    backgroundColor: '#fff',
  },
}) => {
  const { i18n } = useTranslation();
  const handleChange = (newValue) => {
    console.log('handleChange', newValue.format(dateFormatToDisplay));
    onDateSelected(newValue.format(dateFormatForServer));
  };

  const [language, setLanguage] = useState(
    i18n.resolvedLanguage === 'en' ? 'en' : 'nb'
  );

  useEffect(() => {
    setLanguage(i18n.resolvedLanguage === 'en' ? 'en' : 'nb');
    // console.log('languageChanged: ' + i18n.resolvedLanguage);
    if (i18n.resolvedLanguage === 'en') moment.locale('en_GB');
    else moment.locale('nb_NO');
  }, [i18n.resolvedLanguage]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      <DatePicker
        inputFormat={dateFormatToDisplay}
        value={selectedDate}
        onChange={handleChange}
        disabled={isDisabled}
        disablePast
        disableMaskedInput
        shouldDisableDate={oneDayFromCurrentDate}
        maxDate={moment().add(2, 'month')}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ...sxTextFieldHalf,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
                cursor: 'default',
              },
            }}
            disabled
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const DatePickerNextBusinessDayFutureComponent = ({
  isDisabled = false,
  selectedDate,
  onDateSelected,
  sxTextFieldHalf = {
    width: '24.5%',
    minWidth: '24.5%',
    backgroundColor: '#fff',
  },
}) => {
  const { i18n } = useTranslation();
  const handleChange = (newValue) => {
    console.log('handleChange', newValue.format(dateFormatToDisplay));
    onDateSelected(newValue.format(dateFormatForServer));
  };

  const [language, setLanguage] = useState(
    i18n.resolvedLanguage === 'en' ? 'en' : 'nb'
  );

  useEffect(() => {
    setLanguage(i18n.resolvedLanguage === 'en' ? 'en' : 'nb');
    // console.log('languageChanged: ' + i18n.resolvedLanguage);
    if (i18n.resolvedLanguage === 'en') moment.locale('en_GB');
    else moment.locale('nb_NO');
  }, [i18n.resolvedLanguage]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      <DatePicker
        inputFormat={dateFormatToDisplay}
        value={selectedDate}
        onChange={handleChange}
        disabled={isDisabled}
        disablePast
        disableMaskedInput
        shouldDisableDate={nextBusinessDateFromCurrentDate}
        maxDate={moment().add(2, 'month')}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ...sxTextFieldHalf,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
                cursor: 'default',
              },
            }}
            disabled
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const DatePickerWeendDisabledComponent = ({
  isDisabled = false,
  selectedDate,
  onDateSelected,
  sxTextFieldHalf = {
    width: '24.5%',
    minWidth: '24.5%',
    backgroundColor: '#fff',
  },
}) => {
  const { i18n } = useTranslation();
  const handleChange = (newValue) => {
    console.log('handleChange', newValue.format(dateFormatToDisplay));
    onDateSelected(newValue.format(dateFormatForServer));
  };

  const [language, setLanguage] = useState(
    i18n.resolvedLanguage === 'en' ? 'en' : 'nb'
  );

  useEffect(() => {
    setLanguage(i18n.resolvedLanguage === 'en' ? 'en' : 'nb');
    // console.log('languageChanged: ' + i18n.resolvedLanguage);
    if (i18n.resolvedLanguage === 'en') moment.locale('en_GB');
    else moment.locale('nb_NO');
  }, [i18n.resolvedLanguage]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      <DatePicker
        inputFormat={dateFormatToDisplay}
        value={selectedDate}
        onChange={handleChange}
        disabled={isDisabled}
        disablePast
        disableMaskedInput
        shouldDisableDate={disableWeekEndDays}
        maxDate={moment().add(2, 'month')}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              ...sxTextFieldHalf,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black',
                cursor: 'default',
              },
            }}
            disabled
          />
        )}
      />
    </LocalizationProvider>
  );
};
