import { Box, Button, MenuItem, Select, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
const TopBar = () => {
  const { i18n } = useTranslation();

  const iosClick = () => {
    // ReactGA.event({
    //   category: 'Home',
    //   action: 'Click',
    //   label: 'Apple Store',
    // });
    window.open(
      'https://apps.apple.com/us/app/lunsj-p%C3%A5-d%C3%B8ra/id1617445017',
      '_blank'
    );
  };

  const andriodClick = () => {
    // ReactGA.event({
    //   category: 'Home',
    //   action: 'Click',
    //   label: 'Google Play Store',
    // });

    window.open(
      'https://play.google.com/store/apps/details?id=no.digiberry.lunchapp&gl=NO',
      '_blank'
    );
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      sx={{
        backgroundColor: '#000',
        pl: 1,
        pr: 1,
      }}
    >
      <Stack
        direction='row'
        sx={{
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Stack direction='row' alignItems='center' columnGap='2px'>
          <EmailIcon
            style={{
              color: '#8fb869',
              fontSize: 16,
            }}
          />
          <a href='mailto:support@foodfe.app' style={{ color: '#8fb869' }}>
            support@foodfe.app
          </a>
        </Stack>
        <Stack direction='row' alignItems='center' columnGap='2px'>
          <CallIcon style={{ color: '#8fb869', fontSize: 16 }} />
          <a
            href='tel:41350051'
            style={{
              color: '#8fb869',
            }}
          >
            41-35-00-51
          </a>
        </Stack>

        <Select
          value={
            i18n.language && i18n.language == 'en' ? 'English' : 'Norwegian'
          }
          style={{
            color: 'white',
          }}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value === 'English' ? 'en' : 'dr');
          }}
        >
          <MenuItem value='English'>EN</MenuItem>
          <MenuItem value='Norwegian'>NO</MenuItem>
        </Select>
      </Stack>
      <Stack
        direction='row'
        sx={{
          contentVisibility: {
            xs: 'hidden',
            md: 'visible',
          },
          alignItems: 'center',
          columnGap: 1,
        }}
      >
        <Box
          sx={{
            color: '#fff',
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => window.open('https://admin.foodfe.app', '_blank')}
        >
          Sign in
        </Box>
        <Box
          component='img'
          src='assets/images/banners/gp.webp'
          alt='play store'
          sx={{
            height: '3em',
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={andriodClick}
        />
        <Box
          component='img'
          src='assets/images/banners/as.webp'
          alt='app store'
          sx={{
            height: '3em',
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={iosClick}
        />
      </Stack>
    </Stack>
  );
};

export default TopBar;
