import React from 'react';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import CartItem from './CartItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const CartHolder = ({ isCheckoutScreen = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const cartItems = useSelector((state) => state.cart.items);
  const totalPrice = cartItems.reduce(
    (totalPrice, item) => totalPrice + item.totalPrice,
    0
  );
  const cartItemsCount = cartItems === undefined ? 0 : cartItems.length;
  const navigateToCheckOutScreen = () => {
    navigate(`${location.pathname.replace('home', 'checkout')}`);
  };

  const handleAddMore = () => {
    navigate(-1);
  };

  return (
    <Paper>
      <Box
        sx={{
          color: '#354463',
          padding: 3,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          background: '#F9F3EC',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant='h5' component='span'>
              {t('Cart')}
            </Typography>
            <Typography
              variant='subtitle2'
              mt={1}
              color='primary'
              component='span'
            >
              {`${cartItemsCount} ${t('Item')}`}
            </Typography>
          </Box>
          <Button
            variant='contained'
            sx={{
              p: '10.5px 12px',
              borderRadius: '8px',
            }}
            onClick={handleAddMore}
          >
            Add more
          </Button>
        </Box>
        {cartItemsCount === 0 ? (
          ''
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              mt: '20px',
            }}
          >
            {cartItems.map((item) => (
              <CartItem key={item.id} item={item} />
            ))}
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: '19px',
                }}
              >
                {isCheckoutScreen ? t('To Pay') : t('SubTotal')}
                <span>{`${totalPrice} kr`}</span>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant='subtitle1'>
                  {t('Exclusive MVA')}
                </Typography>
                <Typography
                  variant='subtitle1'
                  color='orangered'
                  component='span'
                >
                  {t('Delivery charges may be applicable')}
                </Typography>
              </Box>
            </Box>

            {isCheckoutScreen ? (
              ''
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Button
                  variant='rounded'
                  color='primary'
                  disabled={cartItemsCount === 0}
                  onClick={navigateToCheckOutScreen}
                >
                  {t('Checkout')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default CartHolder;
