import React, { useState } from "react";
import "./Partner.css";

const Partner = () => {
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleInputChange = (event) => {
		const { id, value } = event.target;
		if (id === "name-input") {
			setName(value);
		} else if (id === "phone-input") {
			setPhone(value);
		} else if (id === "email-input") {
			setEmail(value);
		}
	};

	const submitToAPI = (event) => {
		event.preventDefault();

		// Add your API endpoint URL here
		const apiUrl =
			"https://abc1234.execute-api.us-east-1.amazonaws.com/01/contact";

		// Validation checks
		if (name.length < 2) {
			setErrorMessage("Name can not be less than 2 characters");
			return;
		}

		const mobileRegex = /[0-9]{10}/;
		if (!mobileRegex.test(phone)) {
			setErrorMessage("Please enter a valid mobile number");
			return;
		}

		if (email === "") {
			setErrorMessage("Please enter your email id");
			return;
		}

		const emailRegex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
		if (!emailRegex.test(email)) {
			setErrorMessage("Please enter a valid email address");
			return;
		}

		const data = {
			name: name,
			phone: phone,
			email: email,
		};

		fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (response.ok) {
					setSuccessMessage(
						"Your request has been submitted. We will connect with you soon!!"
					);
					setErrorMessage("");
					document.getElementById("contact-form").reset();
					// You may use state or props to reload the component or handle routing
				} else {
					throw new Error("Error sending message.");
				}
			})
			.catch((error) => {
				setErrorMessage("Please try again later.");
			});
	};

	return (
		<div className="container-fluid partner">
			<div className="row">
				<div className="col-md-12">
					<p
						data-aos="fade-up"
						data-aos-duration="800"
						data-aos-easing="ease-in-out"
						className="do-you-share-our text-center">
						Do you share our vision for a sustainable, inclusive food future?
						Are you excited about the idea of blending technology and food? Then
						we'd love to hear from you! Tell us how you'd like to partner with
						us.
					</p>
					<div className="mt-4 text-center">
						<input
							data-aos="fade-up"
							data-aos-duration="800"
							data-aos-easing="ease-in-out"
							className="btn btn-secondary"
							data-bs-toggle="modal"
							data-bs-target="#partnerModal"
							type="submit"
							value={"Partner With Us"}
						/>
					</div>
				</div>
			</div>

			<div
				className="modal fade"
				id="partnerModal"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								Partner with us
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"></button>
						</div>
						<form id="contact-form" onSubmit={submitToAPI}>
							<div className="modal-body">
								<div className="form-floating mb-3">
									<input
										type="text"
										className="form-control"
										id="name-input"
										placeholder=""
										onChange={handleInputChange}
										value={name}
									/>
									<label htmlFor="name-input">Name</label>
								</div>
								<div className="form-floating mb-3">
									<input
										type="text"
										className="form-control"
										id="phone-input"
										placeholder=""
										onChange={handleInputChange}
										value={phone}
									/>
									<label htmlFor="phone-input">Phone Number</label>
								</div>
								<div className="form-floating mb-3">
									<input
										type="email"
										className="form-control"
										id="email-input"
										placeholder=""
										onChange={handleInputChange}
										value={email}
									/>
									<label htmlFor="email-input">Email address</label>
								</div>

								{successMessage && <p className="text-primary">{successMessage}</p>}
								{errorMessage && <p className="text-danger">{errorMessage}</p>}
							</div>

							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-bs-dismiss="modal">
									Close
								</button>
								<button type="submit" className="btn btn-primary">
									Send Enquiry
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Partner;
