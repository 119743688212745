import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

// import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';

// ReactGA.send({
//   hitType: 'pageview',
//   page: '/app-user-registration',
//   title: 'app-user-registration',
// });

const typographyStyle = {
  fontFamily: 'Rubik',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 500,
};

const schema = yup.object({
  name: yup.string().trim().required('Name is Required'),

  email: yup
    .string()
    .trim()
    .required('Email is Required')
    .email('Invalid Email'),
});

const useStyles = makeStyles({
  TextField: {
    width: '300px',
    height: '40px',
    marginBottom: '16px',
    marginTop: '8px',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: 16,
    lineheight: 16,
  },
});

const OnBoardingForm = ({ isError = false, submitForm }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [phone, setPhone] = useState('');

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmitOnBoardingUserInfo = async (data) => {
    if (phone.length < 10) {
      toast.error('Please enter valid phone number');
      return;
    }
    const formData = { ...data, phone: `+${phone}` };
    console.log(formData);
    submitForm(formData);
  };

  const primaryMenu = document.getElementById('primary-menu');
  if (primaryMenu) {
    primaryMenu.style.visibility = 'hidden';
  }

  return (
    <>
      <Box
        mt={2}
        sx={{
          fontFamily: 'inter',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '20px',
        }}
      >
        {t(
          'Please provide the following details to register for the lunch plan at work:'
        )}
      </Box>
      {isError && (
        <Box
          sx={{
            mt: 2,
            fontFamily: 'inter',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '19px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#EB5757',
          }}
        >
          {t('Registration failed - Please try again after sometime.')}
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmitOnBoardingUserInfo)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            mt: 4,
          }}
        >
          <Box>
            <Typography
              variant='h6'
              component='h5'
              style={typographyStyle}
              gutterBottom
            >
              {t('Name')}
            </Typography>
            <FormControl>
              <Controller
                control={control}
                defaultValue=''
                name='name'
                render={({ field }) => (
                  <TextField
                    className={classes.TextField}
                    {...field}
                    variant='outlined'
                    type='text'
                    error={!!errors?.name}
                    helperText={t(errors?.name?.message)}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box>
            <Typography variant='h6' style={typographyStyle} gutterBottom>
              {t('Phone Number')}
            </Typography>
            <Box display='flex'>
              <PhoneInput
                country={'no'}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant='h6' style={typographyStyle} gutterBottom>
              {t('Email')}
            </Typography>
            <FormControl>
              <Controller
                name='email'
                defaultValue=''
                control={control}
                render={({ field }) => (
                  <TextField
                    className={classes.TextField}
                    {...field}
                    type='email'
                    variant='outlined'
                    error={!!errors?.email}
                    helperText={t(errors?.email?.message)}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Button
            color='primary'
            variant='rounded'
            type='submit'
            fullWidth
            sx={{
              padding: '10px 30px',
              backgroundColor: '#354463',
              border: '1px solid #354463',
              borderRadius: '60px',
              color: '#FFFFFF',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(53, 68, 99, 1)',
              },
              mb: 12,
            }}
          >
            {t('Submit')}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default OnBoardingForm;
