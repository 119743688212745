import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BoxSummaryAction } from '../../../../store/box-summary-slice';
const TableIconActive =
  process.env.PUBLIC_URL + '/assets/menu_planner/table_icon_active.svg';
const TableIconInActive =
  process.env.PUBLIC_URL + '/assets/menu_planner/table_icon_inactive.svg';
const GridIconActive =
  process.env.PUBLIC_URL + '/assets/menu_planner/grid_icon_active.svg';
const GridIconInActive =
  process.env.PUBLIC_URL + '/assets/menu_planner/grid_icon_inactive.svg';

const borderRemovedSelect = {
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
};

const FoodBoxSummaryActionComponent = () => {
  const dispatch = useDispatch();
  const { isViewAsTable } = useSelector((state) => state.boxSummary);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: '0 2.4rem',
        backgroundColor: '#FBFBFB',
      }}
    >
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 3,
        }}
      >
        <Box>
          <Typography component='span' variant='s16_w500'>
            Sort by:
          </Typography>
          &nbsp;
          <Select sx={borderRemovedSelect} value='Recently added'>
            <MenuItem value='Recently added'>Recently added</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography component='span' variant='s16_w500'>
            Filter by:{' '}
          </Typography>
          &nbsp;
          <Select sx={borderRemovedSelect} value='Show all Boxes'>
            <MenuItem value='Show all Boxes'>Show all Boxes</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography component='span' variant='s16_w500'>
            Meal type:{' '}
          </Typography>
          &nbsp;
          <Select sx={borderRemovedSelect} value='All meal types'>
            <MenuItem value='All meal types'>All meal types</MenuItem>
          </Select>
        </Box>
      </Box> */}
      <Box></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          py: '1.6em',
        }}
      >
        <Typography component='span' variant='s16_w500'>
          View as:
        </Typography>
        &nbsp;&nbsp;
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: 1,
          }}
        >
          <Box
            component='img'
            src={isViewAsTable ? GridIconInActive : GridIconActive}
            alt='Grid Icon'
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              if (isViewAsTable) {
                dispatch(BoxSummaryAction.toggleViewAsState());
              }
            }}
          />
          <Box
            component='img'
            src={isViewAsTable ? TableIconActive : TableIconInActive}
            alt='Table Icon'
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              if (!isViewAsTable) {
                dispatch(BoxSummaryAction.toggleViewAsState());
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FoodBoxSummaryActionComponent;
