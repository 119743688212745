import { Chip, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';
import AddNewTagDialog from './AddNewTagDialog';

const AddTagsComponent = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.createMeal.tags);

  const handleDelete = (tag) => {
    dispatch(CreateMealAction.deleteTag(tag));
  };

  const handleEnterPressed = (event) => {
    if (event.key === 'Enter') {
      const tag = event.target.value;
      if (tags.find((item) => item === tag)) {
        toast.error('Tag already added');
        return;
      }
      dispatch(CreateMealAction.addTag(tag));
      event.target.value = '';
    }
  };

  return (
    <Grid2 container columnSpacing={1}>
      <Grid2 xs={2}>
        <Typography variant='s16_w600' component='span'>
          Add Tags:*
        </Typography>
      </Grid2>
      <Grid2 xs={5} direction='column'>
        <AddNewTagDialog />
        <Stack
          direction='row'
          spacing={1}
          mt={1}
          display='flex'
          flexWrap='wrap'
        >
          {tags.map((tag) => {
            return (
              <Chip
                key={tag.english}
                label={`${tag.english}/${tag.nor}`}
                onDelete={() => handleDelete(tag)}
                sx={{
                  backgroundColor: '#FFE8E1',
                  color: '#342C47',
                  fontFamily: 'Inter',
                  fontSize: '1.4rem',
                  fontWeight: 500,
                  '& .MuiChip-deleteIcon': {
                    color: 'red',
                  },
                }}
              />
            );
          })}
        </Stack>
      </Grid2>
    </Grid2>
  );
};

export default AddTagsComponent;
