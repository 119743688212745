import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CartLocationMap from './location_map/CartLocationMap';
import OrderDetail from './OrderDetail';
import CartHolder from './cart_info/CartHolder';
import Footer from '../../Components/layout/footer/Footer';
import { containerWidth } from '../../utils/dimentions';
import Header from '../../Components/layout/header/Header';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CartItem from './cart_info/CartItem';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { CartActions } from '../../store/cart-slice';
import { useCheckoutMeetingFood } from './hooks/useMeetingFoodData';
import { DatePickerTwoDaysFutureComponent } from '../../Components/DatePickerComponent';
let StudentSvg = process.env.PUBLIC_URL + '/assets/Students.svg';
let FileSvg = process.env.PUBLIC_URL + '/assets/file.svg';
let EditSvg = process.env.PUBLIC_URL + '/assets/edit.svg';
const sxBoxContainer = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

const sxBoxImg = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  borderRadius: '50%',
  padding: '12px',
  height: '48px',
  width: '48px',
  backgroundColor: '#342C47',
  border: '2px solid #fff',
};

const sxTextField = {
  width: '100%',
};
const sxTextFieldHalf = {
  width: '100%',
};

const schema = yup.object({
  name: yup.string().trim().required('Name is required'),
  phone: yup
    .string()
    .trim()
    .required('Phone number is required')
    .min('5', 'Invalid Phone Number'),
  email: yup.string().trim().required('Email address is Required').email(),
  addressLine: yup.string().trim().required('Delivery Address is Required'),
  city: yup.string().trim().required('City is Required'),
  postalCode: yup.string().trim().required('Postal Code is Required'),
  suggestion: yup.string().trim(),
});

const MobileCartScreen = () => {
  const navigate = useNavigate();
  const orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
  const dispatch = useDispatch();

  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedDate, setSelectedDate] = useState(
    orderDetails?.deliverydate ?? ''
  );

  console.log('selectedDate: ', selectedDate);

  const cartItems = useSelector((state) => state.cart.items);
  const totalPrice = cartItems.reduce(
    (totalPrice, item) => totalPrice + item.totalPrice,
    0
  );
  const cartItemsCount = cartItems === undefined ? 0 : cartItems.length;
  const goBack = () => {
    navigate(-1);
  };
  let toastId;
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: orderDetails ?? {},
    resolver: yupResolver(schema),
  });

  const handleErr = (err) => {
    console.log('handleErr');
    toast.dismiss(toastId);

    try {
      toast.error(err.response.data.message);
    } catch (err) {
      toast.error(t('Something went wrong!'));
    }
  };

  const handleSuccessResponse = () => {
    console.log('Success');
    toast.dismiss(toastId);
    dispatch(CartActions.clearCart());
    toast.success(t('Order has been placed successfully.'));
    localStorage.removeItem('orderDetails');
    navigate('/order-placed', {
      replace: true,
    });
  };

  const { mutate: checkoutMeetingFood } = useCheckoutMeetingFood(
    handleSuccessResponse,
    handleErr
  );

  const onSubmitCheckoutMeals = async (data) => {
    console.log('onSubmitCheckout');
    console.dir(data);
    data.deliveryAddress = `${data.addressLine}, ${data.postalCode} ${data.city}`;

    if (selectedDate.length === 0) {
      toast.error(t('Please select delivery date'));
      return;
    }

    data.deliverydate = selectedDate;
    data.items = cartItems.map((item) => {
      return { MealId: item.id, quantity: item.quantity };
    });
    toastId = toast.loading(t('Processing your request!'));
    checkoutMeetingFood(data);
  };

  //Catch order details
  window.onpopstate = () => {
    localStorage.setItem(
      'orderDetails',
      JSON.stringify({ ...getValues(), deliverydate: selectedDate })
    );
  };

  return (
    <Box
      xs={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifySelf: 'flex-start',
          minWidth: '100%',
          alignItems: 'center',
          mb: 5,
          mt: 3,
          p: 2,
        }}
      >
        <div onClick={goBack}>
          <ArrowBackIosIcon />
        </div>
        <Typography variant='h6' component='span'>
          Your order details
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{
            backgroundColor: '#F9F3EC',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              minWidth: '100%',
              p: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant='h5' component='span'>
                {t('Cart')}
              </Typography>
              <Typography
                variant='subtitle2'
                mt={1}
                color='primary'
                component='span'
              >
                {`${cartItemsCount} ${t('Item')}`}
              </Typography>
            </Box>
            <Typography variant='h4' color='#384461' mr={2}>
              ${totalPrice} NOK
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: '#F9F3EC',
          }}
        >
          {cartItemsCount === 0 ? (
            ''
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                mt: '20px',
              }}
            >
              {cartItems.map((item) => (
                <CartItem key={item.id} item={item} />
              ))}
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: '19px',
                  }}
                >
                  {t('To Pay')}
                  <span>{`${totalPrice} kr`}</span>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant='subtitle1'>
                    {t('Exclusive MVA')}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    color='orangered'
                    component='span'
                  >
                    {t('Delivery charges may be applicable')}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={goBack}
                  fullWidth
                >
                  {t('Add More')}
                </Button>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      <form onSubmit={handleSubmit(onSubmitCheckoutMeals)}>
        <Box
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 4,
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={sxBoxImg}>
              <img
                src={StudentSvg}
                alt='profile'
                style={{
                  maxHeight: '24px',
                  maxWidth: '24px',
                }}
              />
            </Box>
          </Box>

          <Box
            ml={1}
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
            }}
            mb={7}
          >
            <Box>
              <Typography variant='body1' gutterBottom>
                {t('Receivers name')}*
              </Typography>
              <FormControl variant='outlined' mb={2} sx={sxTextField}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='name'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      type='text'
                      error={!!errors?.name}
                      helperText={t(errors?.name?.message)}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box>
              <Typography variant='body1' gutterBottom>
                {t('Receivers phone')}*
              </Typography>
              <FormControl variant='outlined' mb={2} sx={sxTextFieldHalf}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='phone'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      type='number'
                      error={!!errors?.phone}
                      helperText={t(errors?.phone?.message)}
                    />
                  )}
                />
              </FormControl>{' '}
            </Box>
            <Box>
              <Typography variant='body1' gutterBottom>
                {t('Receivers email')}
              </Typography>
              <FormControl variant='outlined' mb={2} sx={sxTextField}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='email'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      type='text'
                      error={!!errors?.email}
                      helperText={t(errors?.email?.message)}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={sxBoxImg}>
              <img
                src={FileSvg}
                alt='file'
                style={{ height: '24px', width: '24px' }}
              />
            </Box>
          </Box>
          <Box ml={1} mb={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant='body1' gutterBottom>
                {t('Delivery address')}*
              </Typography>
              <FormControl variant='outlined' mb={2} sx={sxTextField}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='addressLine'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      type='text'
                      error={!!errors?.addressLine}
                      helperText={t(errors?.addressLine?.message)}
                    />
                  )}
                />
              </FormControl>

              <FormControl variant='outlined' mb={2} sx={sxTextFieldHalf}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='postalCode'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      type='text'
                      placeholder={t('Postal Code')}
                      error={!!errors?.postalCode}
                      helperText={t(errors?.postalCode?.message)}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='outlined' mb={2} sx={sxTextFieldHalf}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='city'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      type='text'
                      placeholder={t('City')}
                      error={!!errors?.city}
                      helperText={t(errors?.city?.message)}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box
              mt={1}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant='body1' gutterBottom>
                {t('Delivery date')}*
              </Typography>

              <DatePickerTwoDaysFutureComponent
                sxTextFieldHalf={{
                  width: '100%',
                  minWidth: '100%',
                  backgroundColor: '#fff',
                }}
                selectedDate={selectedDate}
                onDateSelected={(selectedDate) => setSelectedDate(selectedDate)}
              />
              <Typography variant='h7' color='#342C4766'>
                {t(
                  'Delivery date should be at least two business days from the current date'
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={sxBoxImg}>
              <img
                src={EditSvg}
                alt='Edit'
                style={{ height: '24px', width: '24px' }}
              />
            </Box>
          </Box>
          <Box
            ml={1}
            sx={{
              width: '400px',
            }}
          >
            <FormControl variant='outlined' mb={2} sx={{ width: '100%' }}>
              <Controller
                control={control}
                defaultValue=''
                name='suggestion'
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    type='text'
                    multiline
                    placeholder={t('Any suggestions? We will pass it on..')}
                    rows={2}
                    error={!!errors?.suggestion}
                    helperText={t(errors?.suggestion?.message)}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Button
            variant='contained'
            sx={{
              mt: 7,
              p: '1em 3em',
              textTransform: 'none',
              ml: 1,
              mb: 8,
            }}
            type='submit'
            disabled={cartItemsCount === 0}
          >
            {t('Place an order')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default MobileCartScreen;
