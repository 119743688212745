import { apiPath } from '../../../../config';
import { axiosInstance } from '../../AxiosInstance';

export const getAllargiesData = async () => {
  return await axiosInstance({
    method: 'GET',
    url: apiPath.getAllergies,
  });
};

export const getAllMealsData = async () => {
  return await axiosInstance.get(apiPath.rawMeals);
};

export const createMeal = async (body) => {
  return await axiosInstance.post(apiPath.meals, {
    ...body,
  });
};

export const updateMealBox = async (data) => {
  // console.log('updateMealBox', data.externalId);
  console.dir(data);
  return await axiosInstance.patch(apiPath.meals + `/${data.externalId}`, {
    ...data,
  });
};

export const patchMealBox = async ({ externalId, isDisabled }) => {
  console.log('patchMealBox', externalId, isDisabled);
  return await axiosInstance.patch(apiPath.meals + `/${externalId}`, {
    isDisabled,
  });
};
