import { Grid } from '@mui/material';
import React from 'react';
import FoodBoxGridItem from './FoodBoxGridItem';
import { useSelector } from 'react-redux';

const FoodBoxGridList = () => {
  const { filteredList } = useSelector((state) => state.meals);

  return (
    <Grid container rowSpacing={3} columnSpacing={5}>
      {filteredList !== undefined &&
        filteredList?.map((item) => {
          return <FoodBoxGridItem key={item.id} item={item} />;
        })}
    </Grid>
  );
};

export default FoodBoxGridList;
