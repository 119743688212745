import { Box, Typography } from '@mui/material';
import React from 'react';
import classes from './PhoneMenuHeadCategory.module.css';
const rightArrowStyle = {
  color: '#342C47',
  opacity: '0.5',
};
const PhoneMenuHeadCategory = () => {
  return (
    <div className={classes.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: {
            xs: 'center',
            md: 'left',
          },
        }}
      >
        <Typography variant='h3' component='span' color='primary'>
          Indian
        </Typography>
        &nbsp;
        <Typography variant='h3' component='span' color='secondary'>
          Cuisine
        </Typography>
      </Box>

      <Typography variant='body1' textAlign='center'>
        Relish the timeless classics and regional favorites from{' '}
        <strong>India</strong>, as our menu brings home the essence of India's
        culinary diversity
      </Typography>

      <div className={classes.noteLayout}>
        <Typography variant='body1' color='secondary' textAlign='center'>
          Place your orders at least <strong>3 days</strong> in advance. For any
          specific requirements or queries, reach out to us at{' '}
          <strong>46 389 345</strong>.
        </Typography>
      </div>
    </div>
  );
};

export default PhoneMenuHeadCategory;
