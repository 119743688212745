import { createTheme } from '@mui/material';

export const menuPlannerTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#342C47',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },

    secondary: {
      main: '#F5835E',
    },

    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: '#fff',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },

    body: {
      main: '#6C6C6C',
    },

    white: {
      main: '#fff',
    },

    green: {
      main: '#97b772',
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },

  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    allVariants: {
      fontFamily: 'Inter',
    },
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '6rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '8rem',
    },
    h2: {
      fontSize: '4.8rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    h3: {
      fontSize: '3.2rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '2.4rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h4_v2: {
      color: '#FFF',
      fontSize: '2.4rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    h5: {
      color: '#342C47',
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },

    h7: {
      color: '#342C47',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    s10_w600_white: {
      color: '#ffffff',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    s12_w400: {
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    s12_w400_grey: {
      color: '#717074',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    s12_w400_text_clickable: {
      color: '#342C47',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    s12_w500_grey: {
      color: '#717074',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    s12_w600_grey: {
      color: '#342C47',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    s14_w400: {
      color: '#342C47',
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    s14_w500: {
      color: '#342C47',
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    s14_w600: {
      color: '#342C47',
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    body1: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      color: '#342C47',
    },
    body2: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },

    cartInfo: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      color: '#342C47',
    },
    subtitle1: {
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '2rem',
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    s16_w400: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    s16_w500_orange: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      color: '#F5825E',
    },
    s16_w500: {
      color: '#342C47',
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    s16_w700: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      color: '#342C47',
    },
    s20_w500: {
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
      color: '#342C47',
    },
    s20_w600: {
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      color: '#342C47',
    },
    s20_w700: {
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      color: '#342C47',
    },
    s16_w600: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#342C47',
      lineHeight: 'normal',
    },
    s16_w600_orange: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      color: '#F5825E',
    },
    s18_w500: {
      color: '#342C47',
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    s18_w600: {
      color: '#342C47',
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    s18_w700: {
      color: '#342C47',
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    s24_w500: {
      color: '#342C47',
      fontSize: '2.4rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    s24_w600: {
      color: '#342C47',
      fontSize: '2.4rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    s28_w700: {
      color: '#342C47',
      fontSize: '2.8rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.8rem',
          fontFamily: 'Poppins',
          '&.Mui-disabled': {
            background: '#35446340',
            color: '#fff',
          },
        },
      },
      variants: [
        {
          props: { variant: 'rounded' },
          style: {
            backgroundColor: '#354463',
            borderRadius: 60,
            color: 'white',
            ':hover': {
              backgroundColor: '#252F45',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: '#342C47',
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            color: 'white',
          },
        },
        {
          props: { variant: 'text' },
          style: { color: '#342C47' },
        },
      ],
    },
  },
});
