import { Box, Breadcrumbs, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ActionComponent from '../components/ActionComponent';
import PlannerFooter from '../../../Components/menu_planner/PlannerFooter';
import ScheduleTopComponent from './ScheduleTopComponent';
import ScheduleTable from './ScheduleTable';
import { scheduleDummyData } from '../../../utils/dummyData';
import { useQuery } from 'react-query';
import { getAllSchedule } from './ScheduleApi';
import dayjs from 'dayjs';
import { dateFormatForServer } from '../../../utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import LoaderProgressComponent from '../../../Components/menu_planner/LoaderProgressComponent';
import ScheduleSearchComponent from './ScheduleSearchComponent';
import { queryKeys } from '../../../utils/queryKeys';
import { ScheduleSummaryAction } from '../../../store/schedule-summary-slice';
import DailyMealScheduleTable from './DailyMealScheduleTable';
import { getAllMealsData } from '../food_box/create_food_box/CreateFoodBoxApi';
import { MealAction } from '../../../store/meal-slice';
import { testMealPlan } from './create_schedule/CreateScheduleScreen';

const SchedulesScreen = () => {
  const { language } = useSelector((state) => state.globalUi);
  const { scheduleType, fromDate, toDate } = useSelector(
    (state) => state.scheduleSummary
  );
  const { user } = useSelector((state) => state.auth);

  console.log('scheduleType', scheduleType);
  const dispatch = useDispatch();
  const {
    data: allScheduleData,
    error,
    isLoading,
  } = useQuery(
    [queryKeys.getAllScheduleData, language, fromDate, toDate, scheduleType],
    getAllSchedule,
    {
      onSuccess: (res) => {
        dispatch(
          ScheduleSummaryAction.addAllSchedules(
            res?.data !== undefined && res?.data?.length > 0
              ? res.data?.flatMap((item) => {
                  return item.scheduledMeals.map((meal) => {
                    return {
                      ...meal,
                      date: item.date,
                      category: 'allday',
                      title: meal.name,
                      calendarId: item?.mealPlan?.name ?? 'Standard',
                      scheduleType: item?.scheduleType,
                      mealPlan: item?.mealPlan,
                    };
                  });
                })
              : []
          )
        );
      },
    }
  );

  useQuery(queryKeys.getMealData, getAllMealsData, {
    onSuccess: (response) => {
      console.log(queryKeys.getMealData);
      console.dir(response.data);
      dispatch(MealAction.addAllMeals(response.data));
    },
    refetchOnWindowFocus: false,
    cacheTime: 5 * 60 * 1000, //Milliseconds
  });

  return (
    <Box>
      <Box
        className='content-horizontal-margin'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
          mt: 3,
          mb: 3,
        }}
      >
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography variant='s16_w500_orange'>Schedule</Typography>
        </Breadcrumbs>
        <ScheduleTopComponent mealsPlan={user?.canteen?.mealPlans} />
        {/* <ScheduleTopComponent mealsPlan={testMealPlan} /> */}
        {/* <ActionComponent />
         */}
        {scheduleType === 'daily_fare' ? null : <ScheduleSearchComponent />}
        {isLoading ? (
          <LoaderProgressComponent />
        ) : scheduleType === 'daily_fare' ? (
          <Stack gap={5}>
            <DailyMealScheduleTable />
            <DailyMealScheduleTable isDefaultLunch={false} />
          </Stack>
        ) : (
          <ScheduleTable />
        )}
      </Box>
      <PlannerFooter />
    </Box>
  );
};

export default SchedulesScreen;
