import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
const SadFaceIcon =
  process.env.PUBLIC_URL + '/assets/menu_planner/sad_face_icon.svg';

const NoScheduledItemFound = () => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #BDBDBD',
        p: 16,
        gap: 6,
      }}
    >
      <Box component='img' src={SadFaceIcon} />
      <Stack gap={2} justifyContent='center' alignItems='center'>
        <Typography variant='s28_w700'>Opps!</Typography>
        <Typography variant='s14_w400'>No scheduled meal</Typography>
      </Stack>
    </Stack>
  );
};

export default NoScheduledItemFound;
