import React from 'react';
import './Slider.css';
import Slider1 from './Slider1.png';
import { useNavigate } from 'react-router-dom';

export const Slider = () => {
  const navigate = useNavigate();
  const handleOnClickExploreNewMenus = () => {
    navigate('/indiancuisine');
  };
  return (
    <div className='slider'>
      <div className='container-fluid px-4'>
        <div className='row'>
          <div className='col-md-6 px-4 d-flex justify-content-center align-items-center ps-5'>
            <div>
              <h2
                data-aos='fade-up'
                data-aos-delay='10'
                data-aos-duration='500'
                data-aos-easing='ease-in-out'
              >
                Your Tech-Powered Food Fairy's In <span>Norway!</span>
              </h2>
              <p
                data-aos='fade-up'
                data-aos-delay='30'
                data-aos-duration='800'
                data-aos-easing='ease-in-out'
                className='mt-4'
              >
                With cutting-edge technology and a passion for diverse flavors,
                we're revolutionizing the way you experience food. We don't just
                deliver meals; we design culinary experiences around your
                lifestyle. Are you ready to embark on this extraordinary
                journey?
              </p>
              {/* <button
                data-aos='fade-up'
                data-aos-delay='50'
                data-aos-duration='1000'
                data-aos-easing='ease-in-out'
                className='btn btn-primary mt-3'
                onClick={handleOnClickExploreNewMenus}
              >
                Explore New Menus
              </button> */}
            </div>
          </div>
          <div className='col-md-6 text-center text-md-end'>
            <img
              data-aos='fade-left'
              data-aos-duration='1000'
              className='mt-5 mt-md-0 ms-4 ms-md-4 img-fluid'
              src={Slider1}
              alt='Slider'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
