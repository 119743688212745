import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartActions } from '../../store/cart-slice';
import { useCheckoutMeetingFood } from './hooks/useMeetingFoodData';
import { DatePickerTwoDaysFutureComponent } from '../../Components/DatePickerComponent';
let StudentSvg = process.env.PUBLIC_URL + '/assets/Students.svg';
let FileSvg = process.env.PUBLIC_URL + '/assets/file.svg';
let EditSvg = process.env.PUBLIC_URL + '/assets/edit.svg';

const sxPaper = {
  padding: '24px',
};

const sxBoxContainer = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

const sxBoxImg = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  borderRadius: '50%',
  padding: '12px',
  height: '48px',
  width: '48px',
  backgroundColor: '#342C47',
  border: '2px solid #fff',
  transform: 'translateY(-30%)',
};

const sxBoxLine = {
  borderRight: '1px solid #3544631A',
  height: '100%',
  margin: '-25% 0 25%',
};

const sxTextField = {
  width: '50%',
};
const sxTextFieldHalf = {
  width: '24.5%',
};

const schema = yup.object({
  name: yup.string().trim().required('Name is required'),
  phone: yup
    .string()
    .trim()
    .required('Phone number is required')
    .min('5', 'Invalid Phone Number'),
  email: yup.string().trim().required('Email address is Required').email(),
  addressLine: yup.string().trim().required('Delivery Address is Required'),
  city: yup.string().trim().required('City is Required'),
  postalCode: yup.string().trim().required('Postal Code is Required'),
  suggestion: yup.string().trim(),
});

const OrderDetail = () => {
  //get Cashed orderDetails if exists
  const orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
const theme = useTheme();
const matches = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location: ', location);

  const [selectedDate, setSelectedDate] = useState(
    orderDetails?.deliverydate ?? ''
  );

  console.log('selectedDate: ', selectedDate);
  const { t } = useTranslation();
  let toastId;
  const cartItems = useSelector((state) => state.cart.items);
  const cartItemsCount = cartItems === undefined ? 0 : cartItems.length;

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: orderDetails ?? {},
    resolver: yupResolver(schema),
  });

  const handleErr = (err) => {
    console.log('handleErr');
    toast.dismiss(toastId);

    try {
      toast.error(err.response.data.message);
    } catch (err) {
      toast.error(t('Something went wrong!'));
    }
  };

  const handleSuccessResponse = () => {
    console.log('Success');
    toast.dismiss(toastId);
    dispatch(CartActions.clearCart());
    toast.success(t('Order has been placed successfully.'));
    localStorage.removeItem('orderDetails');
    navigate('/order-placed', {
      replace: true,
    });
  };

  const { mutate: checkoutMeetingFood } = useCheckoutMeetingFood(
    handleSuccessResponse,
    handleErr
  );

  const onSubmitCheckoutMeals = async (data) => {
    console.log('onSubmitCheckout');
    console.dir(data);
    data.deliveryAddress = `${data.addressLine}, ${data.postalCode} ${data.city}`;

    if (selectedDate.length === 0) {
      toast.error(t('Please select delivery date'));
      return;
    }

    data.deliverydate = selectedDate;
    data.items = cartItems.map((item) => {
      return { MealId: item.id, quantity: item.quantity };
    });
    toastId = toast.loading(t('Processing your request!'));
    checkoutMeetingFood(data);
  };

  //Catch order details
  window.onpopstate = () => {
    localStorage.setItem(
      'orderDetails',
      JSON.stringify({ ...getValues(), deliverydate: selectedDate })
    );
  };

  return (
    <Box sx={sxPaper}>
      <Typography component='h1' variant='h3'>
        {t('Your order details')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmitCheckoutMeals)}>
        <Grid container mt={5}>
          <Grid item xs={1}>
            <Box sx={sxBoxContainer}>
              <Box sx={sxBoxImg}>
                <img
                  src={StudentSvg}
                  alt='profile'
                  style={{
                    maxHeight: '24px',
                    maxWidth: '24px',
                  }}
                />
              </Box>
              <Box sx={sxBoxLine} />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box
              ml={1}
              sx={{
                display: 'flex',
                gap: 2,
                flexDirection: 'column',
              }}
              mb={7}
            >
              <Box>
                <Typography variant='body1' gutterBottom>
                  {t('Receivers name')}*
                </Typography>
                <FormControl variant='outlined' mb={2} sx={sxTextField}>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='name'
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant='outlined'
                        type='text'
                        error={!!errors?.name}
                        helperText={t(errors?.name?.message)}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box>
                <Typography variant='body1' gutterBottom>
                  {t('Receivers phone')}*
                </Typography>
                <FormControl variant='outlined' mb={2} sx={sxTextFieldHalf}>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='phone'
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant='outlined'
                        type='number'
                        error={!!errors?.phone}
                        helperText={t(errors?.phone?.message)}
                      />
                    )}
                  />
                </FormControl>{' '}
              </Box>
              <Box>
                <Typography variant='body1' gutterBottom>
                  {t('Receivers email')}
                </Typography>
                <FormControl variant='outlined' mb={2} sx={sxTextField}>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='email'
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant='outlined'
                        type='text'
                        error={!!errors?.email}
                        helperText={t(errors?.email?.message)}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box sx={sxBoxContainer}>
              <Box sx={sxBoxImg}>
                <img
                  src={FileSvg}
                  alt='file'
                  style={{ height: '24px', width: '24px' }}
                />
              </Box>
              <Box sx={sxBoxLine} />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box ml={1} mb={6}>
              <Box>
                <Typography variant='body1' gutterBottom>
                  {t('Delivery address')}*
                </Typography>
                <FormControl variant='outlined' mb={2} sx={sxTextField}>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='addressLine'
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant='outlined'
                        type='text'
                        error={!!errors?.addressLine}
                        helperText={t(errors?.addressLine?.message)}
                      />
                    )}
                  />
                </FormControl>{' '}
                <Box
                  mt={1}
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <FormControl variant='outlined' mb={2} sx={sxTextFieldHalf}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='postalCode'
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant='outlined'
                          type='text'
                          placeholder={t('Postal Code')}
                          error={!!errors?.postalCode}
                          helperText={t(errors?.postalCode?.message)}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant='outlined' mb={2} sx={sxTextFieldHalf}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='city'
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant='outlined'
                          type='text'
                          placeholder={t('City')}
                          error={!!errors?.city}
                          helperText={t(errors?.city?.message)}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box mt={1}>
                <Typography variant='body1' gutterBottom>
                  {t('Delivery date')}*
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                  }}
                >
                  <DatePickerTwoDaysFutureComponent
                    selectedDate={selectedDate}
                    onDateSelected={(selectedDate) =>
                      setSelectedDate(selectedDate)
                    }
                  />
                  <Typography variant='h7' maxWidth={'40%'} color='#342C4766'>
                    {t(
                      'Delivery date should be at least two business days from the current date'
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box sx={sxBoxContainer}>
              <Box sx={sxBoxImg}>
                <img
                  src={EditSvg}
                  alt='Edit'
                  style={{ height: '24px', width: '24px' }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box
              ml={1}
              sx={{
                width: '400px',
              }}
            >
              <FormControl variant='outlined' mb={2} sx={{ width: '100%' }}>
                <Controller
                  control={control}
                  defaultValue=''
                  name='suggestion'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      type='text'
                      multiline
                      placeholder={t('Any suggestions? We will pass it on..')}
                      rows={2}
                      error={!!errors?.suggestion}
                      helperText={t(errors?.suggestion?.message)}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Button
              variant='contained'
              sx={{ mt: 7, p: '1em 3em', textTransform: 'none', ml: 1, mb: 8 }}
              type='submit'
              disabled={cartItemsCount === 0}
            >
              {t('Place an order')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default OrderDetail;
