import axios from 'axios';
import { useMutation } from 'react-query';
import { headers } from '../../../utils/headerUtils';
import { baseUrl } from '../../../utils/utils';

const checkoutMeetingFood = (info) => {
  console.log(`checkoutMeetingFood ${info}`);
  console.dir(info);
  return axios.post(`${baseUrl}/public/catering-orders`, info, {
    headers: headers,
  });
};

export const getMyOrdersForOrgAdmin = async ({ queryKey }) => {
  const [key, TargetOrganizationId, TargetUserId, language, fromDate, toDate] =
    queryKey;
  console.log(
    'getMyOrdersForOrgAdmin',
    key,
    TargetOrganizationId,
    TargetUserId,
    fromDate,
    toDate
  );
  return axios({
    method: 'GET',
    url: `${baseUrl}/v1/organizations/${TargetOrganizationId}/catering-orders`,
    headers: {
      TargetOrganizationId,
      TargetUserId,
    },
    params: {
      fromDate,
      toDate,
      language: language,
    },
  });
};

export const getMyOrdersForCateringAdmin = async ({ queryKey }) => {
  const [key, TargetOrganizationId, TargetUserId, language, fromDate, toDate] =
    queryKey;
  console.log(
    'getMyOrdersForCateringAdmin',
    key,
    TargetOrganizationId,
    TargetUserId,
    fromDate,
    toDate
  );
  return axios({
    method: 'GET',
    url: `${baseUrl}/catering-orders`,
    headers: {
      TargetOrganizationId,
      TargetUserId,
    },
    params: {
      fromDate,
      toDate,
      language: language,
    },
  });
};

export const getOrgOrders = async ({ queryKey }) => {
  const [key, canteenId, language, fromDate, toDate] = queryKey;
  console.log('getAllOrgOrders', key, canteenId, fromDate, toDate);
  return axios({
    method: 'GET',
    url: `${baseUrl}/canteens/${canteenId}/catering-orders`,
    params: {
      fromDate,
      toDate,
      language: language,
    },
  });
};

const deleteMeetingFoodOrder = (id, userId) => {
  return axios.delete(`${baseUrl}/catering-orders/${id}`, {
    headers: {
      TargetUserId: userId,
    },
  });
};

export const useCheckoutMeetingFood = (onSuccess, onError) => {
  return useMutation(checkoutMeetingFood, {
    onSuccess,
    onError,
  });
};

export const useDeleteMeetingFood = (onSuccess, onError) => {
  return useMutation(deleteMeetingFoodOrder, {
    onSuccess,
    onError,
  });
};
