import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AuthActions } from '../../../store/auth-slice';
import { useEffect } from 'react';
import LoaderProgressComponent from '../../../Components/menu_planner/LoaderProgressComponent';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(AuthActions.logout()).then(() => {
      navigate('/meal-planner/', { replace: true });
    });
  }, []);

  return <LoaderProgressComponent />;
};

export default Logout;
