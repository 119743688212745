import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FoodBoxTopComponent = () => {
  const navigate = useNavigate();
  const { mealList } = useSelector((state) => state.meals);

  const handleAddFoodBox = () => {
    navigate('create-food-box');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
        }}
      >
        <Typography variant='s24_w600' color='primery'>
          Food Boxes
        </Typography>
        <Box
          sx={{
            padding: '0.65rem 1.6rem',
            border: '1px solid #46B52A',
            borderRadius: '5rem',
          }}
        >
          <Typography component='span' variant='s16_w500' color='primery'>
            Active:
          </Typography>
          &nbsp;&nbsp;
          <Typography component='span' variant='s16_w700' color='primery'>
            {mealList?.length ?? 0}
          </Typography>
        </Box>
        {/* <Box
          sx={{
            padding: '0.65rem 1.6rem',
            border: '1px solid#F5825E',
            borderRadius: '5rem',
          }}
        >
          <Typography component='span' variant='s16_w500' color='primery'>
            Drafts:
          </Typography>
          &nbsp;&nbsp;
          <Typography component='span' variant='s16_w700' color='primery'>
            07
          </Typography>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 2,
          justifyContent: 'flex-end',
        }}
      >
        {/* <Button variant='outlined'>Export (Excel/PDF)</Button> */}
        <Button variant='contained' onClick={handleAddFoodBox}>
          Create a Food Box
        </Button>
      </Box>
    </Box>
  );
};

export default FoodBoxTopComponent;
