import { Box, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DialogRemoveAllItemFromCart from './DialogRemoveAllItemFromCart';
import { getMeatTypeIconsUrl } from '../../../utils/foodUtils';
import CloseButtonComponent from '../../../Components/CloseButtonComponent';
import CartAddRemoveComponent from '../../../Components/CartAddRemoveComponent';

const CartItem = ({ item }) => {
  const { name, price, quantity } = item;
  // const dispatch = useDispatch();
  // const removeAllItemsFromCart = () => {
  //   dispatch(CartActions.removeAllItemsFromCart(item.id));
  // };
  const [open, setOpen] = useState(false);

  const handleClickDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = (value) => {
    setOpen(false);
    console.log(value);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography component='span' variant='h7'>
          {name}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {/* <img
            src={getMeatTypeIconsUrl(item.meatType)}
            alt='meal type'
            style={{
              height: '16px',
              width: '16px',
              objectFit: 'cover',
            }}
          /> */}
          {/* {item?.tags?.includes('Halal') || item?.tags?.includes('NW Halal') ? (
            <img
              src={halalIcon}
              alt='is Halal'
              style={{
                height: '22px',
                width: '22px',
                objectFit: 'cover',
              }}
            />
          ) : (
            ''
          )} */}
          <CloseButtonComponent onClose={handleClickDialogOpen} />
          <DialogRemoveAllItemFromCart
            open={open}
            onClose={handleDialogClose}
            item={item}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CartAddRemoveComponent item={item} />
        <Typography component='span' variant='h7'>
          {`${price === undefined ? 0 : price * quantity} kr`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CartItem;
