import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ScheduleSummaryAction } from '../../../store/schedule-summary-slice';

const ScheduleTopComponent = ({ mealsPlan }) => {
  const { scheduleList, scheduleType, showHistory } = useSelector(
    (state) => state.scheduleSummary
  );

  console.log('Show History', showHistory);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCreateScheduleClick = () => {
    navigate('create-schedule');
  };

  useEffect(() => {
    (function () {
      dispatch(
        ScheduleSummaryAction.updateScheduleType(
          mealsPlan?.Lunch && mealsPlan?.Lunch?.length > 0 ? 'Lunch' : 'Dinner'
        )
      );
    })();
  }, [mealsPlan]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
          gap: 8,
        }}
      >
        <Stack direction='row' columnGap={1}>
          <Typography variant='s24_w600' color='primery'>
            Schedule
          </Typography>
          {scheduleType === 'daily_fare' ? null : (
            <Box
              sx={{
                padding: '0.65rem 1.6rem',
                border: '1px solid #BDBDBD',
                borderRadius: '5rem',
              }}
            >
              <Typography component='span' variant='s16_w500' color='primery'>
                {scheduleList?.length ?? 0}
              </Typography>
            </Box>
          )}
        </Stack>
        <RadioGroup
          row
          value={scheduleType}
          onChange={(e) =>
            dispatch(ScheduleSummaryAction.updateScheduleType(e.target.value))
          }
        >
          <FormControlLabel
            value='Lunch'
            disabled={!(mealsPlan?.Lunch && mealsPlan?.Lunch?.length > 0)}
            control={<Radio />}
            label='Lunch'
          />
          <FormControlLabel
            value='Dinner'
            disabled={!(mealsPlan?.Dinner && mealsPlan?.Dinner?.length > 0)}
            control={<Radio />}
            label='Dinner'
          />
          <FormControlLabel
            value='daily_fare'
            control={<Radio />}
            label='Daily fare'
          />
        </RadioGroup>
      </Stack>
      <Stack direction='row'>
        {scheduleType === 'daily_fare' ? null : (
          <FormControlLabel
            control={
              <Checkbox
                checked={showHistory}
                onChange={() =>
                  dispatch(ScheduleSummaryAction.updateShowHistory())
                }
              />
            }
            label='Show History'
          />
        )}
        <Button variant='contained' onClick={handleCreateScheduleClick}>
          Create a Schedule
        </Button>
      </Stack>
    </Box>
  );
};

export default ScheduleTopComponent;
