import React from "react";
import "./Footer.css";
import insta from './insta.svg'
import facebook from './facebook.svg'
import linkedin from "./linkedin.svg";
import logo from "./food-fe-alt.png";


export const Footer = () => {
	return (
		<div className="footer">
			<div className="container">
				<div data-aos="fade-down" className="row">
					<div className="col-md-2">
						<h3>Contact</h3>
						<p className="mt-4">
							Ole Deviks Vei 10,
							<br />
							0666 Oslo
						</p>
					</div>
					<div className="col-md-2 text-center text-md-start">
						<p className="mt-5">
							info@dcx.no <br />
							tlf: +47 46 38 93 45
						</p>
					</div>
					<div className="col-md-4 text-center">
						<img className="img-fluid my-5 my-md-0" alt="Group" src={logo} />
					</div>
					<div className="col-md-2 offset-md-2">
						<h3>Follow Us</h3>
						<a target="_blank" href="#">
							<img className="img-fluid" alt="Image" src={insta} />
						</a>
						<a target="_blank" href="#">
							<img className="img-fluid ms-2" alt="Image" src={facebook} />
						</a>
						<a target="_blank" href="#">
							<img className="img-fluid ms-2" alt="Image" src={linkedin} />
						</a>
					</div>

					<div className="col-md-12 text-center">
						<p className="mt-5">Copyright @2023. All rights reserved.</p>
					</div>
				</div>
			</div>
		</div>
	);
};
