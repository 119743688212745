import React from 'react';
import classes from './HeadMenuItem.module.css';
import { Typography } from '@mui/material';
const HeaderMenuItem = ({ imgPath, title }) => {
  return (
    <div className={classes.container} key={title}>
      <img src={imgPath} alt={title} className={classes.img} />
      <Typography variant='body1'>{title}</Typography>
    </div>
  );
};

export default HeaderMenuItem;
