import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#F5835E',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },

    secondary: {
      main: '#342C47',
    },

    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: '#fff',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },

    body: {
      main: '#6C6C6C',
    },

    white: {
      main: '#fff',
    },

    green: {
      main: '#97b772',
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },

  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    allVariants: {
      fontFamily: 'Poppins',
    },
    h1: {
      fontSize: '6rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '8rem',
    },
    h2: {
      fontSize: '4.8rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    h3: {
      fontSize: '3.2rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '2.4rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    h4_v2: {
      color: '#FFF',
      fontSize: '2.4rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    h5: {
      color: '#342C47',
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },

    h7: {
      color: '#342C47',
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    body1: {
      fontSize: '1.8rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    body2: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '2.8rem',
    },
    s16_w600: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '00',
      lineHeight: '2.8rem',
      color: '#342C47',
    },
    cartInfo: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      color: '#342C47',
    },
    subtitle1: {
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '2rem',
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontSize: '1.8rem',
          fontFamily: 'Poppins',
          '&.Mui-disabled': {
            background: '#35446340',
            color: '#fff',
          },
        },
      },
      variants: [
        {
          props: { variant: 'rounded' },
          style: {
            backgroundColor: '#354463',
            borderRadius: 60,
            color: 'white',
            ':hover': {
              backgroundColor: '#252F45',
            },
          },
        },
      ],
    },
  },
});
