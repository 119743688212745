import { yupResolver } from '@hookform/resolvers/yup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import PlannerFooter from '../../../../Components/menu_planner/PlannerFooter';
import QuickAccessComponent from '../../components/QuickAccessComponent';
import CreateFoodBoxTopComponent from './CreateFoodBoxTopComponent';
import AllergiesSection from './food_box_detail_section/AllergiesSection';
import FoodBoxDetailSection from './food_box_detail_section/FoodBoxDetailSection';
import FoodBoxIngredientsSection from './food_box_detail_section/FoodBoxIngredientsSection';
import TriviaImageVideoSection from './food_box_detail_section/TriviaImageVideoSection';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createMeal,
  getAllargiesData,
  updateMealBox,
} from './CreateFoodBoxApi';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import FoodBoxTempIngredientsSection from './food_box_detail_section/FoodBoxTempIngredientsSection';
import { CreateMealAction } from '../../../../store/create-meal-slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMealBoxDataFromRawData } from '../../../../utils/utils';
import { queryKeys } from '../../../../utils/queryKeys';
import { MealAction } from '../../../../store/meal-slice';
const schema = yup.object({
  box_name: yup.string().required('Box name is required'),
  box_name_nw: yup.string().required('Box name is required'),
  description: yup.string().required('Description is required'),
  description_nw: yup.string().required('Description is required'),
  //   unique_name: yup.string().required('Unique name is required'),
  no_of_calories: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .typeError('Calories should be number')
    .min(0, 'Calories should be greater than 0'),
  serving_size: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .typeError('Serving size should be number')
    .min(0, 'Serving size should be greater than 0'),
  trevia: yup.string(),
  trevia_nw: yup.string(),
});

const CreateFoodBoxScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // Get QueryClient from the context
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: location.state?.item
      ? {
          box_name: location.state.item.details[0].name,
          box_name_nw: location.state.item.details[1].name,
          description: location.state.item.details[0].description,
          description_nw: location.state.item.details[1].description,
          //   unique_name: yup.string().required('Unique name is required'),
          no_of_calories: location.state.item.caloriesPer100Qty,
          serving_size: location.state.item.quantity,
        }
      : null,
  });
  let toastId;

  console.log('Location state: ');
  console.dir(location.state);

  useEffect(() => {
    //Reset the window to top if screen is loaded again.
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    if (location.state === null) {
      (function resetCreateMealRedux() {
        dispatch(CreateMealAction.reset());
      })();
    } else {
      const data = getMealBoxDataFromRawData(location.state.item);
      dispatch(CreateMealAction.setMealBoxDataForUpdate(data));
    }
  }, []);

  const {
    isCateringFood,
    isDailyFare,
    dailyFare,
    isVeg,
    isHalal,
    tags,
    meatType,
    allergies,
    hasAlternativePreprationForRecipe,
    mealImage,
    caloriesUnit,
    sizeUnit,
    primaryIngredient,
    additionalIngredient,
    externalId,
  } = useSelector((state) => state.createMeal);

  const foodBoxDetailSectionRef = useRef(null);
  const foodBoxIngredientRef = useRef(null);
  const allergieRef = useRef(null);
  const treviaRef = useRef(null);

  const handleQuickSectionClick = (type) => {
    console.log('handleQuickSectionClick', foodBoxIngredientRef);
    switch (type) {
      case 'food_box_details':
        foodBoxDetailSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'ingredients':
        foodBoxIngredientRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'allergens':
        allergieRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'trevia':
        treviaRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default: {
      }
    }
  };

  useQuery('get-allergies-data', getAllargiesData, {
    refetchOnWindowFocus: false,
    cacheTime: 5 * 60 * 1000, //Milliseconds
    onSuccess: (response) => {
      dispatch(MealAction.addAllAllergies(response.data));
    },
  });

  const handleCreateFoodBoxSubmit = async (data) => {
    const {
      box_name,
      box_name_nw,
      description,
      description_nw,
      unique_name,
      no_of_calories,
      serving_size,
      trevia,
      trevia_nw,
    } = data;

    if (isCateringFood && tags.length === 0) {
      toast.error('Please add at least one Tag');
      return;
    }

    if (primaryIngredient.length === 0) {
      toast.error('Please add at least one primary Ingredient');
      return;
    }

    if (mealImage === null) {
      toast.error('Meal Image is required to create meal-box');
      return;
    }

    const englishDetail = {
      language: 'English',
      name: box_name,
      description: description,
      contents: primaryIngredient.map((i) => i.english).join(),
      tags: isCateringFood ? tags.map((t) => t.english) : null,
    };
    const norwegianDetail = {
      language: 'Norwegian',
      name: box_name_nw,
      description: description_nw,
      contents: primaryIngredient.map((i) => i.nor).join(),
      tags: isCateringFood ? tags.map((t) => t.nor) : null,
    };

    const mealData = {
      externalId: externalId,
      caloriesPer100Qty:
        caloriesUnit === 'kcal'
          ? no_of_calories ?? null
          : (no_of_calories ?? 0) * 4.184,
      quantity: serving_size ?? 0,
      // quantityUnit: sizeUnit,
      isHalal,
      isVeg,
      isCateringFood: isCateringFood,
      meatType: meatType,
      isDefaultLunch: isDailyFare ? dailyFare === 'lunch' : false,
      isDefaultDinner: isDailyFare ? dailyFare === 'dinner' : false,
      images: [mealImage.url],
      thumbnails: [mealImage.thumbnailUrl],
      allergies: allergies.map((allergy) => allergy.externalId),
      allergiesList: allergies,
      selectedAllergies: allergies,
      details: [englishDetail, norwegianDetail],
    };

    console.log('externalId', externalId);

    console.log('Submitting meal Data');
    console.dir(mealData);
    toastId = toast.loading('Processing...');
    if (location.state === null) createMealWithData(mealData);
    else updateMealWithData(mealData);
  };

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    console.log('First Error', firstError);
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const { mutate: createMealWithData } = useMutation(
    (data) => createMeal(data),
    {
      onSuccess: (data, variables, context) => {
        console.log('variables');
        console.dir(variables);
        toast.dismiss(toastId);
        toast.success('Meal created successfully');
        dispatch(CreateMealAction.reset());
        queryClient.invalidateQueries({ queryKey: [queryKeys.getMealData] });

        navigate('/meal-planner/food-boxes/food-box-created-successfully', {
          replace: true,
          state: variables,
        });
      },
      onError: (err) => {
        console.log('handleErr');
        toast.dismiss(toastId);
        try {
          toast.error(err.response.data.message);
        } catch (err) {
          toast.error('Something went wrong!');
        }
      },
    }
  );

  const { mutate: updateMealWithData } = useMutation(
    (param) => updateMealBox(param),
    {
      onSuccess: (data, variables, context) => {
        console.log('variables');
        console.dir(variables);
        toast.dismiss(toastId);
        queryClient.invalidateQueries({ queryKey: [queryKeys.getMealData] });
        toast.success('Meal updated successfully');
        dispatch(CreateMealAction.reset());
        navigate('/meal-planner/food-boxes/food-box-updated-successfully', {
          replace: true,
          state: variables,
        });
      },
      onError: (err) => {
        console.log('handleErr');
        toast.dismiss(toastId);
        try {
          toast.error(err.response.data.message);
        } catch (err) {
          toast.error('Something went wrong!');
        }
      },
    }
  );

  return (
    <Box>
      <form onSubmit={handleSubmit(handleCreateFoodBoxSubmit)}>
        <Box
          className='content-horizontal-margin'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 3,
            mt: 3,
            mb: 3,
          }}
        >
          <Breadcrumbs aria-label='breadcrumb'>
            <Typography variant='s16_w500'>Food Boxes</Typography>
            <Typography variant='s16_w500_orange'>
              {location.state !== null ? 'Edit' : 'Create'} a Food Box
            </Typography>
          </Breadcrumbs>
          <CreateFoodBoxTopComponent />
          <QuickAccessComponent
            handleQuickSectionClick={handleQuickSectionClick}
          />
          <FoodBoxDetailSection
            ref={foodBoxDetailSectionRef}
            control={control}
            errors={errors}
          />
          <FoodBoxTempIngredientsSection ref={foodBoxIngredientRef} />
          <AllergiesSection ref={allergieRef} />
          <TriviaImageVideoSection
            ref={treviaRef}
            control={control}
            errors={errors}
          />
          <Stack
            display='flex'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            {/* <Button variant='outlined' startIcon={<ContentCopyIcon />}>
              Duplicate this food box
            </Button> */}
            <Box />
            <Stack direction='row' gap={2}>
              {/* <Button variant='outlined'>Save as a draft</Button> */}
              <Button variant='contained' type='submit'>
                {location.state === null ? 'Create' : 'Update'} Food Box
              </Button>
            </Stack>
          </Stack>
        </Box>
        <PlannerFooter />
      </form>
    </Box>
  );
};

export default CreateFoodBoxScreen;
