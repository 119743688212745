import { apiPath } from '../../../config';
import { axiosInstance } from '../AxiosInstance';

export const getMealPlan = async () => {
  return axiosInstance.get(apiPath.mealPlans);
};

export const createSchedule = async (data) => {
  return axiosInstance.post(apiPath.mealschedules, data);
};

export const deleteSchedule = async (data) => {
  console.log('deleteSchedule', JSON.stringify(data));
  return axiosInstance.delete(`${apiPath.mealschedules}/${data.date}`, {
    params: { ...data },
  });
};

export const getAllSchedule = async ({ queryKey }) => {
  const [_, language, fromDate, toDate, scheduleType] = queryKey;

  if (scheduleType === 'daily_fare') {
    return;
  }
  return axiosInstance.get(apiPath.mealschedules, {
    params: {
      language,
      scheduleType,
      fromDate,
      toDate,
    },
  });
};
