import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMealAction } from '../../../../../store/create-meal-slice';
const foodTypes = ['Veg', 'Halal'];
const SelectFoodTypeComponent = () => {
  const dispatch = useDispatch();
  const { isHalal, isVeg } = useSelector((state) => state.createMeal);
  return (
    <Grid2 container columnSpacing={1}>
      <Grid2 xs={2}>
        <Typography variant='s16_w600' component='span'>
          Select Food Type:
        </Typography>
      </Grid2>
      <Grid2 xs={10} direction='row' columnGap={3}>
        <FormControl fullWidth>
          <FormGroup
            aria-labelledby='demo-radio-buttons-group-label'
            defaultValue='grams'
            name='radio-buttons-group'
            row
          >
            <FormControlLabel
              sx={{ width: '15%' }}
              control={
                <Checkbox
                  checked={isHalal}
                  onChange={(e) =>
                    dispatch(CreateMealAction.updateIsHalal(e.target.checked))
                  }
                />
              }
              label='Halal'
              key='halal'
            />
            <FormControlLabel
              sx={{ width: '15%' }}
              control={
                <Checkbox
                  checked={isVeg}
                  onChange={(e) =>
                    dispatch(CreateMealAction.updateIsVeg(e.target.checked))
                  }
                />
              }
              label='Veg'
              key='veg'
            />
          </FormGroup>
        </FormControl>
      </Grid2>
    </Grid2>
  );
};

export default SelectFoodTypeComponent;
