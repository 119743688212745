import React from 'react';
import classes from './CartButton.module.css';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const CartButton = () => {
  const cartInfo = useSelector((state) => state.cart.items);

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);
  if (location.pathname === '/') {
    return null;
  }

  const handleOnCartClick = () => {
    navigate('/cart');
  };
  return (
    <div className={classes.container} onClick={handleOnCartClick}>
      <div className={classes.countCircle}>
        <Typography variant='subtitle1'>
          {cartInfo?.length === undefined ? 0 : cartInfo.length}
        </Typography>
      </div>
      <Typography variant='body2' color='primary'>
        Cart
      </Typography>
    </div>
  );
};

export default CartButton;
