import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { dateFormatForServer } from '../utils/dateUtils';

const initialState = {
  scheduleList: [],
  filteredScheduleList: [],
  scheduleType: 'Lunch',
  searchValue: '',
  showOnly: [],
  showHistory: false,
  fromDate: dayjs().format(dateFormatForServer),
  toDate: dayjs().add(30, 'day').format(dateFormatForServer),
};

const createScheduleSummarySlice = createSlice({
  initialState,
  name: 'scheduleSummary',
  reducers: {
    addAllSchedules: (state, action) => {
      state.scheduleList = action.payload;
      //Filtering by schedule type
      state.filteredScheduleList = action.payload;
    },
    searchScheduleList: (state, action) => {
      state.searchValue = action.payload.toLowerCase();

      if (action.payload === '') {
        state.filteredScheduleList = [...state.scheduleList];
        return;
      }

      state.filteredScheduleList = filterSchedule(state);
    },
    updateScheduleType: (state, action) => {
      state.scheduleType = action.payload;
      //   if (action.payload === 'Lunch' || action.payload === 'Dinner') {
      //     state.filteredScheduleList = state.scheduleList?.filter(
      //       (schedule) => schedule.scheduleType === action.payload
      //     );
      //   }
    },
    updateShowOnlyFilter: (state, action) => {
      if (state.showOnly.includes(action.payload)) {
        state.showOnly = state.showOnly.filter(
          (item) => item !== action.payload
        );
      } else {
        state.showOnly.push(action.payload);
      }

      state.filteredScheduleList = filterSchedule(state);
    },
    updateShowHistory: (state, action) => {
      state.showHistory = !state.showHistory;
      if (state.showHistory) {
        state.fromDate = dayjs().add(-30, 'day').format(dateFormatForServer);
        state.toDate = dayjs().format(dateFormatForServer);
      } else {
        state.fromDate = dayjs().format(dateFormatForServer);
        state.toDate = dayjs().add(30, 'day').format(dateFormatForServer);
      }
    },
  },
});

const filterSchedule = (state) => {
  return state.scheduleList?.filter((schedule) => {
    return (
      //   schedule.scheduleType.toLowerCase() === state.scheduleType &&
      (state.showOnly.includes('Halal') ? schedule.isHalal : true) &&
      (state.showOnly.includes('Vegetarian') ? schedule.isVeg : true) &&
      (state.searchValue.length > 0
        ? schedule.name?.toLowerCase().includes(state.searchValue)
        : true)
    );
  });
};

export const ScheduleSummaryAction = createScheduleSummarySlice.actions;
export default createScheduleSummarySlice;
